import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { Alert, Container, Row, Col, Form, Button, FormGroup, Label, Input } from 'reactstrap';
import LoadingScreen from '../loading';

interface State {
    isLoading: boolean
    error: any | undefined
    token: string | undefined
    newPassword: string
    pwdConfirm: string
}

const INITIAL_STATE: State = {
    isLoading: false,
    error: undefined,
    token: undefined,
    newPassword: '',
    pwdConfirm: ''
};

type TParams = { token: string };

interface Props extends RouteComponentProps<TParams> { }

class InserNewPasswordScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let token = this.props.match.params.token
        this.setState({ token })
    }

    updatePwd = async (token: string, newPwd: string) => {
        try {

        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    isInputInvalid = () => {
        if(this.state.newPassword.length < 8) return true
        return this.state.newPassword != this.state.pwdConfirm
    }

    renderError(error: any) {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
        )
    }

    renderNewPwdForm(token: string) {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Form>
                        <FormGroup>
                            <Label for="passwordTitle">Nova Senha</Label>
                            <Input type="password" name="title" id="passwordTitle" placeholder={'Digite a nova senha'} value={this.state.newPassword} onChange={(event: any) => this.setState({ newPassword: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="cfmPasswordTitle">Nova Senha</Label>
                            <Input type="password" name="title" id="cfmPasswordTitle" placeholder={'Confirme a nova senha'} value={this.state.pwdConfirm} onChange={(event: any) => this.setState({ pwdConfirm: event.target.value })} />
                        </FormGroup>
                    </Form>
                    <Button style={{ background: '#5977ff' }} block disabled={this.isInputInvalid()} onClick={() => this.updatePwd(token, this.state.newPassword)}>Trocar Senha</Button>
                </Col>
            </Row>
        )
    }

    render() {
        let { error, isLoading, token } = this.state
        if (isLoading) return <LoadingScreen />
        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            {error && this.renderError(error)}
            {token && this.renderNewPwdForm(token)}
        </Container>)
    }
}

const InserNewPasswordScreen = compose<Props, {}>(withRouter)(InserNewPasswordScreenBase)

export default InserNewPasswordScreen;
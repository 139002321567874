export default class DeckCard {
    id: string
    type: string
    pos: number
    constructor(data: any) {
        this.id = data.id
        this.type = data.type
        this.pos = data.pos
    }

    getData(): any {
        return {
            id: this.id,
            type: this.type,
            pos: this.pos
        }
    }
}

export class FirstCard extends DeckCard {
    text: string
    image: string
    constructor(data: any) {
        super(data)
        this.text = data.text
        this.image = data.image
    }

    getData(): any {
        return {
            ...super.getData(),
            text: this.text,
            image: this.image
        }
    }
}

export class DescriptionCard extends DeckCard {
    title: string
    texts: Array<string>
    image: string
    constructor(data: any) {
        super(data)
        this.title = data.title
        this.texts = data.texts
        this.image = data.image
    }

    getData(): any {
        return {
            ...super.getData(),
            texts: this.texts,
            image: this.image
        }
    }
}

export class LastCard extends DeckCard {
    text: string
    image: string
    constructor(data: any) {
        super(data)
        this.text = data.text
        this.image = data.image
    }

    getData(): any {
        return {
            ...super.getData(),
            text: this.text,
            image: this.image
        }
    }
}


export class WordSearchCard extends DeckCard {
    title: string
    word: string
    constructor(data: any) {
        super(data)
        this.title = data.title
        this.word = data.word
    }

    getData(): any {
        return {
            ...super.getData(),
            title: this.title,
            word: this.word
        }
    }
}

export class QuestionCard extends DeckCard {
    question: string
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    correctAnswer: number
    constructor(data: any) {
        super(data)
        this.question = data.question
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.correctAnswer = data.correctAnswer
    }

    getData(): any {
        let data = {
            ...super.getData(),
            question: this.question,
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswer: this.correctAnswer
        }
        if(this.answer2) data.answer2 = this.answer2
        if(this.answer3) data.answer3 = this.answer3
        return data
    }
}

export class InfoCard extends DeckCard {
    image: string
    info: string
    constructor(data: any) {
        super(data)
        this.image = data.image
        this.info = data.info
    }

    getData(): any {
        return {
            ...super.getData(),
            image: this.image,
            info: this.info
        }
    }
}

export function getDeckCard(data: any) {
    if(data.type == DeckCardTypes.First) {
        return new FirstCard(data)
    } else if(data.type == DeckCardTypes.Description) {
        return new DescriptionCard(data)
    } else if(data.type == DeckCardTypes.Info) {
        return new InfoCard(data)
    } else if(data.type == DeckCardTypes.WordSearch) {
        return new WordSearchCard(data)
    } else if(data.type == DeckCardTypes.Question) {
        return new QuestionCard(data)
    } else if(data.type == DeckCardTypes.Last) {
        return new LastCard(data)
    } else {
        throw `Unknown card type: ${data.type}`
    }
}

export const DeckCardTypes = {
    First: 'first',
    Description: 'description',
    Info: 'info',
    WordSearch: 'word_search',
    Question: 'question',
    Last: 'last'
}
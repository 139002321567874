import React, { Component, FunctionComponent, CSSProperties } from 'react';
import { Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeckCard from '../../models/DeckCards';

interface Props { cards: Array<DeckCard>, renderCard: (item: any) => JSX.Element, currentIndex: number }

interface State {
    isOpen: boolean;
    error: any | undefined;
};

const INITIAL_STATE: State = {
    isOpen: false,
    error: undefined
};

export default class Swipe extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    getStyleForPosition(pos: number): CSSProperties {
        return {
            ...cardStyle,
            marginTop: 10 + ((pos - this.props.currentIndex) * 2)
        }
    }

    renderNoMoreCards() {
        return (<p>No more cards</p>)
    }

    renderCardItem = (card: any) => {
        if (!this.props.cards.length) {
            return this.renderNoMoreCards()
        } else {
            return (<div key={`$123${card.pos}`} style={this.getStyleForPosition(card.pos)}>
                {this.props.renderCard(card)}
            </div>)
        }
    } 

    renderCards() {
        return this.props.cards.filter((val) => { return (val.pos >= this.props.currentIndex) && (val.pos <= this.props.currentIndex + 3) }).sort((a, b) => b.pos - a.pos).map(this.renderCardItem)
    }

    render() {
        return (<div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>{this.renderCards()}</div>)
    }
}

const cardStyle: CSSProperties = {
    display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center', background: 'white',
    textAlign: 'center', padding: 10, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', transition: '0.3s',
    height: '70vh', position: 'absolute', width: '100%'
}

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash'

import DeckCard, { QuestionCard, WordSearchCard, FirstCard, InfoCard, DescriptionCard, DeckCardTypes, LastCard } from '../../models/DeckCards';
import WordSearchComponent from '../../deck/WordSearchComponent'
import { Spring } from 'react-spring/renderprops';
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { faTimes, faChevronCircleLeft, faChevronCircleRight, faCheckCircle, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import Swipe from './swipe';
import { QuestionAnswer, Question } from '../../models/Question'
import AnswerComponent from './AnswerComponent'
import { DeckCardReport, QuestionCardReport, WordSearchCardReport } from '../../models/DeckCardsReport';

const imageHeight = 100

interface State {
    isNavBarMenuOpen: boolean,
    currentIndex: number,
    questions: Array<Question>,
    isCardFullScreen: boolean,
    error: string | undefined
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentIndex: 0,
    questions: [],
    isCardFullScreen: false,
    error: undefined
}

interface Props { deckCards: Array<DeckCard>, subModuleId: string, onExit: () => void, onFinish: (startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>) => void, companyColor: string | undefined, companyPic: string | undefined }

export default class DeckCardsScreen extends Component<Props, State> {

    deckCardsReport: Array<DeckCardReport<DeckCard>>
    startTime: number
    endTime: number | undefined

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.deckCardsReport = []
        this.state = { ...INITIAL_STATE, questions: this.props.deckCards.filter(card => card.type == DeckCardTypes.Question).map(question => { return new Question(question.id, QuestionAnswer.NotAnswered) }) }
    }

    getCardQuestion(card: DeckCard) {
        let filter = this.state.questions.filter(question => { return question.id == card.id })
        return filter[0]
    }

    addCardReport(card: DeckCard) {
        if (this.deckCardsReport.filter(cardReport => cardReport.card.id == card.id).length > 0) return
        if (card.type == DeckCardTypes.Question) {
            let cardReport = new QuestionCardReport(card as QuestionCard)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        } else if (card.type == DeckCardTypes.WordSearch) {
            let cardReport = new WordSearchCardReport(card as WordSearchCard)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        } else {
            let cardReport = new DeckCardReport(card)
            cardReport.addEnterTime()
            this.deckCardsReport.push(cardReport)
        }
    }

    addCardReportExitTime(card: DeckCard) {
        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id == card.id))
        if(cardReport!.exitTimes.length < cardReport!.enterTimes.length) {
            cardReport!.addExitTime()
        }
    }

    onAnswerSelected = (answerNumber: number) => {
        let questions = this.state.questions
        let currentCard = this.props.deckCards[this.state.currentIndex] as QuestionCard
        let currentQuestion = _.head(questions.filter(question => { return question.id == currentCard.id }))
        if (currentQuestion) {
            if (currentQuestion.answer != QuestionAnswer.NotAnswered) return
            currentQuestion.selectedAnswer = answerNumber
            let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id == currentCard.id))
            let questionCardReport = cardReport as QuestionCardReport
            questionCardReport.answerTime = (new Date()).getTime()
            if (answerNumber == currentCard.correctAnswer) {
                currentQuestion.answer = QuestionAnswer.CorrectAnswer
                questionCardReport.questionAnswer = QuestionAnswer.CorrectAnswer
            } else {
                currentQuestion.answer = QuestionAnswer.WrongAnswer
                questionCardReport.questionAnswer = QuestionAnswer.WrongAnswer
            }
            this.setState({ questions: [...this.state.questions] })
        }
    }

    onWordFound = (wordSearchCard: WordSearchCard) => {

        let cardReport = _.head(this.deckCardsReport.filter(cardReport => cardReport.card.id == wordSearchCard.id))
        let wordSearchCardReport = cardReport as WordSearchCardReport
        wordSearchCardReport.foundTime =  (new Date()).getTime()
        wordSearchCardReport.wordFound = true

        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            let currentIndex = this.state.currentIndex
            this.setState({ currentIndex: currentIndex + 1, isCardFullScreen: false })
        } else {
            this.setState({ isCardFullScreen: false })
        }
    }

    onNextClick = (card: DeckCard) => {
        let currentIndex = this.state.currentIndex
        this.addCardReportExitTime(card)

        if (this.state.currentIndex < this.props.deckCards.length - 1) {
            this.setState({ currentIndex: currentIndex + 1 })
        } else {
            if(!this.endTime) this.endTime = (new Date()).getTime()
            this.props.onFinish(this.startTime, this.endTime!, this.deckCardsReport)
        }
    }

    onPreviousClick = (card: DeckCard) => {
        this.addCardReportExitTime(card)

        let currentIndex = this.state.currentIndex 
        this.setState({ currentIndex: currentIndex - 1 })
    }

    renderQuestionCard(card: QuestionCard) {
        let question = this.getCardQuestion(card)
        let correctAnswer = card.correctAnswer
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 1, textAlign: 'center', color: 'black', fontFamily: 'Montserrat' }}>{card.question}</div>
                    <AnswerComponent answerText={card.answer0} selectedAnswer={question.selectedAnswer} answerNumber={0}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />
                    <AnswerComponent answerText={card.answer1} selectedAnswer={question.selectedAnswer} answerNumber={1}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />
                    {card.answer2 && <AnswerComponent answerText={card.answer2} selectedAnswer={question.selectedAnswer} answerNumber={2}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
                    {card.answer3 && <AnswerComponent answerText={card.answer3} selectedAnswer={question.selectedAnswer} answerNumber={3}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
                </div>
            </Col>
        </Row>)
    }

    animatedImage(image: string) {
        let random = Math.random()
        if (random > 0.75) {
            return (<Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>
                    <img src={image} style={{ height: imageHeight }} />
                </div>}
            </Spring>)
        } else if (random > 0.5) {
            return (<Spring
                from={{ marginLeft: -1000 }}
                to={{ marginLeft: 0 }}>
                {props => <div style={props}>
                    <img src={image} style={{ height: imageHeight }} />
                </div>}
            </Spring>)
        } else if (random > 0.25) {
            return (<Spring
                from={{ marginRight: -1000 }}
                to={{ marginRight: 0 }}>
                {props => <div style={props}>
                    <img src={image} style={{ height: imageHeight }} />
                </div>}
            </Spring>)
        } else {
            return (<Spring
                from={{ marginTop: -1000 }}
                to={{ marginTop: 0 }}>
                {props => <div style={props}>
                    <img src={image} style={{ height: imageHeight }} />
                </div>}
            </Spring>)
        }
    }

    renderWordSearchCard() {
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 1, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontSize: 20 }}>Caça-palavras</div>
                    <div style={{ margin: 1, textAlign: 'center', color: 'black', fontFamily: 'Montserrat', fontSize: 12 }}>Encontre a palavra certa clicando nas letras.</div>
                    <Button style={{ background: this.props.companyColor || '#000', margin: 10 }} onClick={() => { this.setState({ isCardFullScreen: true }) }}><FontAwesomeIcon icon={faSortAlphaUp} /> Jogar</Button>
                </div>
            </Col>
        </Row>)
    }

    renderCard = (card: DeckCard) => {
        let currentCard = card.pos == this.state.currentIndex
        if(currentCard) this.addCardReport(card)
        if (card.type == DeckCardTypes.First) {
            let firsCard = card as FirstCard
            return (<Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>
                    {currentCard && this.animatedImage(firsCard.image)}
                    <h4 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10 }}>{firsCard.text}</h4>
                </div>}
            </Spring>)
        } else if (card.type == DeckCardTypes.Description) {
            let descriptionCard = card as DescriptionCard
            return (<div>
                {currentCard && this.animatedImage(descriptionCard.image)}
                <p style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 5 }}>{descriptionCard.title}</p>
                {descriptionCard.texts.map((text: string, i: number) => { return (<p key={i.toString()}>{text}</p>) })}
            </div>)
        } else if (card.type == DeckCardTypes.Info) {
            let infoCard = card as InfoCard
            let fontSize = 'normal'
            return (<div>
                {currentCard && this.animatedImage(infoCard.image)}
                <p style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10, fontSize: fontSize == 'normal' ? 22 : 28 }}>{infoCard.info}</p>
            </div>)
        } else if (card.type == DeckCardTypes.WordSearch) {
            return this.renderWordSearchCard()
        } else if (card.type == DeckCardTypes.Question) {
            let questionCard = card as QuestionCard
            return this.renderQuestionCard(questionCard)
        } else if (card.type == DeckCardTypes.Last) {
            let lastCard = card as LastCard
            let fontSize = 'normal'
            return (<div>
                {currentCard && this.animatedImage(lastCard.image)}
                <p style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10, fontSize: fontSize == 'normal' ? 22 : 28 }}>{lastCard.text}</p>
            </div>)
        } else {
            return (<div>
                <p>Desconhecido</p>
            </div>)
        }
    }

    renderHeader() {
        return (<Row>
            <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                <div style={{ alignSelf: 'center', marginTop: 10 }}>
                    <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                </div>
                <div style={{ flex: 1, marginLeft: 5 }}>
                    <div className="text-center" style={{ color: '#1d3256', fontFamily: 'Montserrat' }}>{`${this.state.currentIndex + 1} de ${this.props.deckCards.length}`}</div>
                    <Progress color='dark' value={this.state.currentIndex + 1} max={this.props.deckCards.length} />
                </div>
            </Col>
        </Row>)
    }

    renderNavigationButtons() {

        let currentCard = this.props.deckCards[this.state.currentIndex]
        if (currentCard.type == DeckCardTypes.Question) {
            let cardQuestion = this.getCardQuestion(currentCard)
            if (cardQuestion.answer == QuestionAnswer.NotAnswered) {
                return (
                    <div style={{ display: 'flex', padding: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                        {<Button disabled={true} color="secondary"><FontAwesomeIcon icon={faChevronCircleLeft} /> Anterior</Button>}
                        {<Button disabled={true} color="secondary">Próximo <FontAwesomeIcon icon={faChevronCircleRight} /></Button>}
                    </div>
                )
            }
        }

        let isLastCard = this.state.currentIndex == this.props.deckCards.length - 1

        return (
            <div style={{ display: 'flex', padding: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                {<Button disabled={this.state.currentIndex == 0} color="secondary" onClick={() => { this.onPreviousClick(currentCard) }}><FontAwesomeIcon icon={faChevronCircleLeft} /> Anterior</Button>}
                {<Button disabled={this.state.currentIndex > this.props.deckCards.length - 1} color={isLastCard ? 'primary':  'secondary'} onClick={() => { this.onNextClick(currentCard) }}>{isLastCard ? 'Finalizar':  'Próximo'} <FontAwesomeIcon icon={isLastCard ? faCheckCircle : faChevronCircleRight} /></Button>}
            </div>
        )
    }

    renderDeck() {
        return (<Row>
            <Col style={{ height: '75vh' }} md={{ size: 6, offset: 3 }}>
                <Swipe cards={this.props.deckCards} renderCard={this.renderCard} currentIndex={this.state.currentIndex} />
            </Col>
        </Row>)
    }

    render() {

        let { isCardFullScreen } = this.state

        if (isCardFullScreen) {
            let currentCard = this.props.deckCards[this.state.currentIndex]
            if (currentCard.type == DeckCardTypes.WordSearch) {
                let wordSearchCard = currentCard as WordSearchCard
                return <WordSearchComponent rows={10} columns={10} word={wordSearchCard.word} title={wordSearchCard.title} onWordFound={() => this.onWordFound(wordSearchCard)} onExit={() => this.setState({ isCardFullScreen: false })} />
            }
        }

        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            {this.renderHeader()}
            {this.renderDeck()}
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    {this.renderNavigationButtons()}
                </Col>
            </Row>
        </Container>)
    }

}

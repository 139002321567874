import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'
import * as _ from 'lodash'

import LoadingScreen from '../../loading'
import config from '../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faGamepad, faListOl, faTrophy } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../../constants/routes'
import ContentService from '../../../services/contentService';
import Module from '../../../models/Module';
import SubModule, { SubModuleTypes } from '../../../models/SubModule'
import DeckCard, { DeckCardTypes } from '../../../models/DeckCards';
import DeckCardsScreen from '../../game/DeckCardsScreen';
import { DeckCardReport, QuestionCardReport, WordSearchCardReport } from '../../../models/DeckCardsReport';
import { QuestionAnswer } from '../../../models/Question';

interface State {
    error: string | undefined;
    isLoading: boolean;
    module: Module | undefined;
    subModules: Array<SubModule>;
    moduleId: string | undefined;
    selectedSubModuleId: string | undefined;
    deckCards: Array<DeckCard> | undefined
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    module: undefined,
    subModules: [],
    moduleId: undefined,
    selectedSubModuleId: undefined,
    deckCards: undefined,
};

interface Props extends RouteComponentProps { }

class SubModuleSelectionBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let queryValues = queryString.parse(this.props.location.search)
        if (queryValues && queryValues.moduleId) {
            let moduleId = queryValues.moduleId as string
            this.setState({ moduleId }, () => this.loadModuleAndSubModules(moduleId))
        } else {
            this.setState({ error: 'Missing moduleId in query.' })
        }
    }

    async loadModuleAndSubModules(moduleId: string) {
        this.setState({ isLoading: true, error: undefined })
        try {
            let contentService = new ContentService()
            let { module, subModules } = await contentService.getOpenCompanyModuleAndSubmodules(config.endpoint, '-gmfyit', moduleId)
            this.setState({ module, subModules, isLoading: false })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    playSubModule = async (subModule: SubModule) => {
        try {
            this.setState({ isLoading: true })
            let contentService = new ContentService()
            if(subModule.type == SubModuleTypes.DECK) {
                let deckCards = await contentService.getOpenDeckCards(config.endpoint, subModule.id)
                let sortedDeckCars = deckCards.sort((a, b) => { 
                    return a.pos - b.pos 
                })

                this.setState({ selectedSubModuleId: subModule.id, deckCards: sortedDeckCars, isLoading: false })
            }
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    onExit = () => {
        if(this.state.selectedSubModuleId) {
            this.setState({ selectedSubModuleId: undefined, deckCards: undefined })
        } else {
            this.props.history.goBack()
        }
    }

    onFinish = (startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>) => {
        let questionCardReports = deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type == DeckCardTypes.Question
        }).map(data => data as QuestionCardReport)
        let wordSearchCardReports = deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type == DeckCardTypes.WordSearch
        }).map(data => data as WordSearchCardReport)

        let totalQuestions = questionCardReports.length
        let correctAnsweredQuestions = questionCardReports.filter(qCardReport => qCardReport.questionAnswer == QuestionAnswer.CorrectAnswer).length

        let totalWordSearch = wordSearchCardReports.length
        let wordsFound = wordSearchCardReports.filter(wCardReport => wCardReport.wordFound).length
        
        this.props.history.push(`${ROUTES.TEST}${ROUTES.RESULT}?subModuleId=${this.state.selectedSubModuleId}&totalQuestions=${totalQuestions}&correctQuestions=${correctAnsweredQuestions}&totalWordSearch=${totalWordSearch}&wordsFound=${wordsFound}`)
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderSubModules(subModules: Array<SubModule>) {
        return subModules.map(subModule => {
            return (<div key={subModule.id} className="d-flex flex-column" style={{ marginTop: 10, marginBottom: 5, borderRadius: 2, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: 2 }}>
                    <img style={{ height: 50, width: 50, minWidth: 50, borderRadius: 5, margin: 5 }} src={subModule.pic} />
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>{subModule.title}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Button style={{ background: '#5c959e', margin: 10 }} block onClick={() => { this.playSubModule(subModule) }}><FontAwesomeIcon icon={faGamepad} /> Jogar</Button>
                </div>
            </div>)
        })
    }

    renderHeader(module: Module) {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ width: 100, height: 100, margin: 10, borderRadius: 10 }} src={module.pic} />
                    </div>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 20 }}>
                        {module.title}
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>)
    }

    renderSubModuleSelection(subModules: Array<SubModule>) {
        return (
            <Row style={{ flex: 1, overflow: 'auto' }}>
                <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}>
                    {this.renderSubModules(subModules)}
                </Col>
            </Row>)
    }

    render() {

        let { error, isLoading, module, subModules, selectedSubModuleId, deckCards } = this.state
        let companyPic = 'https://companies-logo-gmfy.s3-us-west-2.amazonaws.com/gmfyit_logo_512.png'

        if (isLoading) { return <LoadingScreen image={companyPic} /> }

        if(selectedSubModuleId && deckCards) {
            return <DeckCardsScreen subModuleId={selectedSubModuleId} onExit={this.onExit} onFinish={this.onFinish} deckCards={deckCards} companyColor={'#5c959e'} companyPic={companyPic} />
        }

        return (<Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            {error && this.renderError(error)}
            {module && this.renderHeader(module)}
            {subModules && this.renderSubModuleSelection(subModules)}
        </Container>)

    }

}

const SubModuleSelectionScreen = compose<Props, {}>(withRouter)(SubModuleSelectionBase)

export default SubModuleSelectionScreen
import React, { Component, FunctionComponent } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGamepad, faPhone, faBrain, faDonate, faLightbulb, faTabletAlt } from '@fortawesome/free-solid-svg-icons'

import ImageBackground from '../../images/programacao.jpg';
import * as ROUTES from '../../constants/routes'
import ProductsScreen from './products'
import { relative } from 'path';


interface State {
    isNavBarMenuOpen: boolean
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false
};

interface Props extends RouteComponentProps { }

class LandingScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.LANDING) }}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.PRODUCTS) }}><FontAwesomeIcon icon={faGamepad} /> Serviços</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.CONTACT) }}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    renderMainContent() {
        return (<Container className="d-flex flex-column justify-content-around" fluid style={{ minHeight: '100vh', background: 'transparent', paddingTop: 50, position: 'relative' }}>
            <Row style={{ marginTop: 20 }}>
                <Col md={{ size: 8, offset: 2 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className="text-center" >
                        <FontAwesomeIcon color='white' icon={faBrain} size='2x' />
                        <h4 style={{ color: 'white', fontFamily: 'Montserrat' }}>Para se manterem competitivas, as empresas precisam encarar novas formas de resolver problemas e pensar processos.</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, justifyContent: 'center', alignItems: 'center' }} className="text-center" >
                        <FontAwesomeIcon color='white' icon={faTabletAlt} size='2x' />
                        <h4 style={{ color: 'white', fontFamily: 'Montserrat' }}>Gamificação, computação em nuvem, bom design e usabilidade são algumas ferramentas poderosas que as tornam cada vez mais competitivas.</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, justifyContent: 'center', alignItems: 'center' }} className="text-center" >
                        <FontAwesomeIcon color='white' icon={faLightbulb} size='2x' />
                        <h4 style={{ color: 'white', fontFamily: 'Montserrat' }}>Nós somos especialistas em soluções e produtos inovadores para atender essas necessidades.</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center" style={{ marginTop: 10 }}>
                    <Button color="success" onClick={() => { this.props.history.push(ROUTES.TEST); }}><FontAwesomeIcon icon={faGamepad} /> Quero Experimentar!</Button>
                </Col>
            </Row>
        </Container>)
    }

    render() {
        return (<div style={{ position: 'relative' }}>
            <img style={{ position: 'absolute', width: '100vw', height: '100%' }} src={ImageBackground} />
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }

}

const LandingScreen = compose<Props, {}>(withRouter)(LandingScreenBase);
export default LandingScreen
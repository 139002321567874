import React, { Component, FunctionComponent } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGamepad, faPhone, faMobileAlt, faBookReader, faEdit } from '@fortawesome/free-solid-svg-icons'

import * as ROUTES from '../../constants/routes'

interface State {
    isNavBarMenuOpen: boolean
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false
};

interface Props extends RouteComponentProps { }

class ProductsScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.LANDING) }}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.PRODUCTS) }}><FontAwesomeIcon icon={faGamepad} /> Serviços</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.CONTACT) }}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    renderMainContent() {
        return (<Container className="d-flex flex-column" fluid style={{ minHeight: '100vh', background: 'white', paddingTop: 50 }}>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ maxHeight: '20vh' }} src={require('../../images/gmfyit.png')} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="text-center" >
                        <h3 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 5 }}>Consultoria e Desenvolvimento</h3></div>
                </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
                <Col md={{ size: 4 }}>
                    <div className="text-center" >
                        <FontAwesomeIcon color='#1d3256' icon={faGamepad} size='3x' />
                        <h3 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Gamificação
                        </h3>
                        <h4 style={{ color: 'black', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Mecânicas de jogos que aumentam o engajamento e retenção dos usuários.
                        </h4>
                    </div>
                </Col>
                <Col md={{ size: 4 }}>
                    <div className="text-center" >
                        <FontAwesomeIcon color='#1d3256' icon={faMobileAlt} size='3x' />
                        <h3 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Tecnologia
                        </h3>
                        <h4 style={{ color: 'black', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Desenvolvimento de aplicações sob demanda.
                        </h4>
                    </div>
                </Col>
                <Col md={{ size: 4 }}>
                    <div className="text-center" >
                        <FontAwesomeIcon color='#1d3256' icon={faEdit} size='3x' />
                        <h3 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Design e Usabilidade
                        </h3>
                        <h4 style={{ color: 'black', fontFamily: 'Montserrat', marginTop: 10 }}>
                            Deixamos o seu app ou site com um design incrível e super fácil de usar.
                        </h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                    <Button color="success" onClick={() => { this.props.history.push(ROUTES.TEST); }}><FontAwesomeIcon icon={faGamepad} /> Quero Experimentar!</Button>
                </Col>
            </Row>
        </Container>)
    }

    render() {
        return (<div>
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }

}

const ProductsScreen = compose<Props, {}>(withRouter)(ProductsScreenBase);
export default ProductsScreen
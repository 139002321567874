import Cookies from "universal-cookie";
import axios from 'axios'

import config from '../config'

export default class TokenHelper {

    setToken(token: string) {
        let cookies = new Cookies()
        cookies.set('token', token, { path: '/' })
    }

    getToken()  {
        let cookies = new Cookies()
        return cookies.get('token')
    }

    getRefreshToken() {
        let cookies = new Cookies()
        return cookies.get('refreshToken')
    }

    async refreshTokenIfNeeded(error: any): Promise<boolean> {
        if(!error.response) return false
        if(!error.response.data) return false
        if(!error.response.data.error) return false
        if(error.response.data.error.code != "Invalid_Access_Token") return false
        try {
            let url = `${config.endpoint}/auth/token`
            let refreshToken = this.getRefreshToken()
            if(!refreshToken) return false
            let result = await axios.post(url, { refreshToken })
            if(result.data.data.token) {
                this.setToken(result.data.data.token)
                return true
            } else {
                return false
            }
        } catch(err) {
            return false
        }
    }
}
import QuizQuestion, { DefaultQuestion, QuizQuestionType, getQuizQuestion } from "./QuizQuestion"
import { QuestionAnswer } from "./Question"
import { QuestionCardReport } from "./DeckCardsReport"
import { QuestionCard } from "./DeckCards"

export class QuizQuestionsReport<T extends QuizQuestion> {

    quizQuestion: T
    
    constructor(quizQuestion: T) {
        this.quizQuestion = quizQuestion
    }

    getData(): any {
        return {
            quizQuestion: this.quizQuestion.getData(),
        }
    }
}

export class DefaultQuestionReport extends QuizQuestionsReport<DefaultQuestion> {
    questionAnswer: QuestionAnswer
    timeToAnswer?: number

    constructor(defaultQuestion: DefaultQuestion) {
        super(defaultQuestion)
        this.questionAnswer = QuestionAnswer.NotAnswered
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer
        }
        if(this.timeToAnswer) data.timeToAnswer = this.timeToAnswer
        return data
    }
}

export function getQuizQuestionReportArray(dataArray: Array<any>): Array<QuizQuestionsReport<QuizQuestion>> {
    let array: Array<QuizQuestionsReport<QuizQuestion>> = []
    for(let i = 0; i < dataArray.length; i++) {
        let data = dataArray[i]
        if(data.quizQuestion.type == QuizQuestionType.Default) {
            let questionCard = getQuizQuestion(data.quizQuestion) as DefaultQuestion
            let questionCardReport = new DefaultQuestionReport(questionCard)
            questionCardReport.questionAnswer = data.questionAnswer
            questionCardReport.timeToAnswer = data.timeToAnswer
            array.push(questionCardReport)
        } else {
            let question = getQuizQuestion(data.quizQuestion)
            let deckCardReport = new QuizQuestionsReport(question)
            array.push(deckCardReport)
        }
    }
    return array
}
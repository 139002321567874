import React, { Component, FunctionComponent } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGamepad, faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import * as ROUTES from '../../constants/routes'

interface State {
    isNavBarMenuOpen: boolean
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false
};

interface Props extends RouteComponentProps { }

class ContactScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.LANDING) }}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.PRODUCTS) }}><FontAwesomeIcon icon={faGamepad} /> Serviços</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.props.history.push(ROUTES.CONTACT) }}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    renderMainContent() {
        return (<Container className="d-flex flex-column" fluid style={{ minHeight: '100vh', background: 'white', paddingTop: 50 }}>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ maxHeight: '20vh', marginBottom: 5 }} src={require('../../images/gmfyit.png')} />
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col className="d-flex flex-column align-items-center" md={{ size: 6 }} style={{ marginTop: 10 }}>
                    <img style={{ width: 100, height: 100, borderRadius: 50, marginBottom: 4 }} src={require('../../images/rafalinkedin.jpg')} />
                    <div className="text-center">
                        <p style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 20 }}>Rafael Saraceni</p>
                        <p style={{ color: '#1d3256', fontFamily: 'Montserrat' }}>Engenheiro especialista em sistemas web. Responsável pelo aplicativo e toda parte de tecnologia.</p>
                    </div>
                    <div><FontAwesomeIcon icon={faEnvelope} /> rafael@gmfy.it</div>
                </Col>
                <Col className="d-flex flex-column align-items-center" md={{ size: 6 }} style={{ marginTop: 10 }}>
                    <img style={{ width: 100, height: 100, borderRadius: 50, marginBottom: 4 }} src={require('../../images/fadilinkedin.jpeg')} />
                    <div className="text-center">
                        <p style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 20 }}>Fadi El Didi</p>
                        <p style={{ color: '#1d3256', fontFamily: 'Montserrat' }}>Engenheiro e especialista em gamificação. Responsável pelas dinâmicas de engajamento e tornar as coisas divertidas.</p>
                    </div>
                    <div><FontAwesomeIcon icon={faEnvelope} /> fadi@gmfy.it</div>
                </Col>
            </Row>
        </Container>)
    }

    render() {
        return (<div>
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }

}

const ContactScreen = compose<Props, {}>(withRouter)(ContactScreenBase);
export default ContactScreen
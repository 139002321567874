export default class Company {
    id: string
    mainColor?: string
    name: string
    pic: string

    constructor(data: any) {
        this.id = data.id
        this.name = data.name
        this.pic = data.pic
        this.mainColor = data.mainColor
    }

    getData(): any {
        return {
            id: this.id,
            name: this.name,
            pic: this.pic,
            mainColor: this.mainColor
        }
    }
}

export class SignUpConfig {
    doc?: DocConfig
    email?: EmailConfig
    phone?: PhoneConfig
    password: boolean
    primaryProperty: string

    constructor(data: any) {
        this.primaryProperty = data.primaryProperty
        this.password = data.password
        if(data.doc) this.doc = new DocConfig(data.doc)
        if(data.email) this.email = new EmailConfig(data.email)
        if(data.phone) this.phone = new PhoneConfig(data.phone)
    }
}

export class PhoneConfig {
    title: string
    placeholder: string
    mask: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.mask = data.mask
        this.required = data.required
    }
}

export class EmailConfig {
    title: string
    placeholder: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.required = data.required
    }
}

export class DocConfig {
    title: string
    placeholder: string
    mask: string
    required: boolean
    constructor(data: any) {
        this.title = data.title
        this.placeholder = data.placeholder
        this.mask = data.mask
        this.required = data.required
    }
}
import axios from 'axios'
import { RankingEntry } from '../models/Ranking'
import User from '../models/User'

export default class RankingService {
    async getModuleRanking(token: string, mainUrl: string, companyId: string, moduleId: string): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/module/default/module_general/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            return new RankingEntry(user, score)
        })
        return rankingEntriesArray
    }

    async getCompanyRanking(token: string, mainUrl: string, companyId: string): Promise<Array<RankingEntry>> {
        let url = `${mainUrl}/mobile/rankings/company/company_general/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let rankingEntriesArray = result.data.data.rankingEntries.map((data: any) => {
            let user = new User(data.user)
            let score = data.score as number
            return new RankingEntry(user, score)
        })
        return rankingEntriesArray
    }
}
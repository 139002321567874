import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, Button, Alert, Form, FormGroup, Label, FormText } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import Cookies from 'universal-cookie'

import LoadingScreen from '../loading'
import Company, { SignUpConfig, DocConfig, EmailConfig } from '../../models/Company'
import EnterService from '../../services/enterService'
import config from '../../config'

import * as ROUTES from '../../constants/routes'

interface State {
    isLoading: boolean
    signUpConfig: SignUpConfig | undefined,
    company: Company | undefined,
    error: any | undefined,
    doc: string | undefined,
    email: string | undefined
}

const INITIAL_STATE: State = {
    isLoading: false,
    signUpConfig: undefined,
    company: undefined,
    error: undefined,
    doc: undefined,
    email: undefined
}

type TParams = { companyId: string };

interface Props extends RouteComponentProps<TParams> { }

class EnterScreenBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let companyId = this.props.match.params.companyId
        let cookies = new Cookies()
        let userData = cookies.get('user')
        if(userData && userData.companyId == companyId) {
            this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.MAIN}`)
        } else {
            this.loadCompanySignUpConfig(companyId)
        }
    }

    async loadCompanySignUpConfig(companyId: string) {
        try {
            this.setState({ isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { company, signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let cookies = new Cookies()
            cookies.set('company', company.getData(), { path: '/' });
            this.setState({ isLoading: false, company, signUpConfig })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask == 'cpf') {
                let cpfWithMask = this.applyCpfMask(newValue)
                return this.setState({ doc: cpfWithMask })
            }
        }

        this.setState({ doc: newValue })
    }

    updateEmail = (newValue: string) => {
        this.setState({ email: newValue })
    }

    applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    isInputInvalid = (signUpConfig: SignUpConfig) => {
        if (signUpConfig.primaryProperty == 'doc') {
            let docConfig = signUpConfig.doc!
            if (docConfig.mask) {
                if (docConfig.mask == 'cpf') {
                    return !this.testCPF(this.state.doc || '')
                }
            }

            return this.state.doc ? this.state.doc.length > 3 : false
        } else if (signUpConfig.primaryProperty == 'email') {
            return !this.isEmailValid(this.state.email || '')
        } else {
            return false
        }
    }

    isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    checkUserCredential = async (primaryProperty: string) => {
        try {
            this.setState({ isLoading: true, error: undefined })
            let value = primaryProperty == 'doc' ? this.state.doc : this.state.email
            let enterService = new EnterService()
            let userExists = await enterService.getUserExists(config.endpoint, primaryProperty, value || '')
            if(userExists) {
                this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.LOGIN}?primaryProperty=${value}`)
            } else {
                this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.SIGN_UP}?primaryProperty=${value}`)
            }
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    renderEnterForm(config: SignUpConfig, company: Company, error: any | undefined) {
        return (<Container className="d-flex flex-column justify-content-center" fluid>
            <Row>
                <Col className="d-flex flex-column justify-content-center" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ minHeight: '20vh', maxHeight: '20vh', marginBottom: 5 }} src={company.pic} />
                    </div>
                    <Form>
                        {config.primaryProperty == 'doc' && this.renderDocInput(config.doc!)}
                        {config.primaryProperty == 'email' && this.renderEmailInput(config.email!)}
                    </Form>
                    <Button style={{ background: company.mainColor || '#000' }} block disabled={this.isInputInvalid(config)} onClick={() => this.checkUserCredential(config.primaryProperty)}>Entrar</Button>
                </Col>
            </Row>
        </Container>)
    }

    renderDocInput(docConfig: DocConfig) {
        return (<FormGroup>
            <Label for="docTitle">{docConfig.title}</Label>
            <Input name="title" id="docTitle" placeholder={docConfig.placeholder} value={this.state.doc || ''} onChange={(event: any) => this.updateDoc(docConfig, event.target.value)} />
        </FormGroup>)
    }

    renderEmailInput(emailConfig: EmailConfig) {
        return (<FormGroup>
            <Label for="docTitle">{emailConfig.title}</Label>
            <Input name="title" id="docTitle" placeholder={emailConfig.placeholder} value={this.state.email || ''} onChange={(event: any) => this.updateEmail(event.target.value)} />
        </FormGroup>)
    }

    renderError(error: any) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    render() {
        let { error, isLoading, signUpConfig, company } = this.state
        if (isLoading) return <LoadingScreen image={company ? company.pic : undefined} />
        return (<div>
            {error && this.renderError(error)}
            {signUpConfig && company && this.renderEnterForm(signUpConfig, company, error)}
        </div>)
    }
}

const EnterScreen = compose<Props, {}>(withRouter)(EnterScreenBase)

export default EnterScreen


import React, { Component, FunctionComponent, CSSProperties } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGamepad, faPhone, faTimes, faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import * as THREE from 'three';
import { OrbitControls } from 'three-orbitcontrols-ts';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import {
    FadiData,
    lowPolyDataTeam, lowPolyDataCubePerson, teamWorkContent, lowPolyDataCylinderPerson1,
    lowPolyDataCylinderPerson2, lowPolyDataTeamBuilding1, lowPolyDataTeamBuilding2, lowPolyDataTeamBuilding3,
    lowPolyDataTeamBuilding4, lowPolyDataTeamBuilding5, lowPolyDataTeamBuilding6, lowPolyDataTeamBuilding7,
    selfKnowledge, impact, empathy, empathyGroup, listen, assertividade, assertividade2, cordialidade1,
    cordialidade2, etica, lowPolyDataTeamBuilding8, lowPolyDataTeamBuilding9
} from './data'
import * as ROUTES from '../../constants/routes'

interface State {
    isNavBarMenuOpen: boolean,
    currentIndex: number
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentIndex: 0
};

interface Props extends RouteComponentProps { }

class TeamWorkScreenBase extends Component<Props, State> {

    lowPolyData: Array<any> = [
        lowPolyDataCubePerson,
        lowPolyDataCylinderPerson1,
        lowPolyDataCylinderPerson2,
        lowPolyDataTeam,
        lowPolyDataTeamBuilding1,
        lowPolyDataTeamBuilding2,
        lowPolyDataTeamBuilding3,
        lowPolyDataTeamBuilding4,
        lowPolyDataTeamBuilding5,
        lowPolyDataTeamBuilding6,
        lowPolyDataTeamBuilding7,
        selfKnowledge,
        impact,
        empathy,
        empathyGroup,
        listen,
        assertividade,
        assertividade2,
        cordialidade1,
        cordialidade2,
        etica,
        lowPolyDataTeamBuilding8,
        lowPolyDataTeamBuilding9,
        cordialidade2
    ]

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    getLowPolyData(): any {
        //return etica
        if (this.state.currentIndex < this.lowPolyData.length) {
            return this.lowPolyData[this.state.currentIndex]
        } else {
            return lowPolyDataTeam
        }
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href={ROUTES.LANDING}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.PRODUCTS}><FontAwesomeIcon icon={faGamepad} /> Produto</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.CONTACT}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    renderMainContent() {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: 'white', paddingTop: 55 }}>
            <Row>
                <Col className='d-flex flex-column justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <ThreeScene data={this.getLowPolyData()} />
                </Col>
            </Row>
            <Row style={{ flex: 1 }}>
                <Col className='d-flex flex-column justify-content-start align-items-center' md={{ size: 6, offset: 3 }}>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'center', fontSize: 20 }}>
                        {teamWorkContent[this.state.currentIndex].text}
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    {this.renderNavigationButtons()}
                </Col>
            </Row>
        </Container>)
    }

    renderNavigationButtons() {
        return (
            <div style={{ display: 'flex', padding: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                {<Button disabled={this.state.currentIndex == 0} color="secondary" onClick={() => { let currentIndex = this.state.currentIndex; this.setState({ currentIndex: currentIndex - 1 }) }}><FontAwesomeIcon icon={faChevronCircleLeft} /> Anterior</Button>}
                {<Button disabled={this.state.currentIndex >= teamWorkContent.length - 1} color="secondary" onClick={() => { let currentIndex = this.state.currentIndex; this.setState({ currentIndex: currentIndex + 1 }) }}>Próximo <FontAwesomeIcon icon={faChevronCircleRight} /></Button>}
            </div>
        )
    }

    render() {
        return (<div>
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }
}

const TeamWorkScreen = compose<Props, {}>(withRouter)(TeamWorkScreenBase);
export default TeamWorkScreen

interface ThreeSceneProps { data: any }

class ThreeScene extends Component<ThreeSceneProps> {

    scene: THREE.Scene | null = null
    camera: THREE.PerspectiveCamera | null = null
    renderer: THREE.WebGLRenderer | null = null
    frameId: number = 0
    mount: HTMLDivElement | null = null
    addedObjects: Array<THREE.Mesh> = []

    init(mount: HTMLDivElement) {

        var cameraRotation: THREE.Euler | null = null
        if (this.renderer != null) {
            //this.addedObjects.forEach(addedObject => this.scene!.remove(addedObject))
            //this.addedObjects = this.props.data.map((data: any, i: number) => this.createMesh(data, i))
            //this.scene!.add(...this.addedObjects)
            cancelAnimationFrame(this.frameId)
            mount.removeChild(this.renderer!.domElement)
        }

        this.mount = mount
        let width = mount.clientWidth
        let height = mount.clientHeight
        this.scene = new THREE.Scene()
        this.scene.background = new THREE.Color('white')
        this.camera = new THREE.PerspectiveCamera(35, width / height, 0.1, 100)
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(width, height);
        let controls = new OrbitControls(this.camera, this.renderer.domElement);
        mount.appendChild(this.renderer.domElement);
        controls.rotateSpeed = 1.0;
        controls.zoomSpeed = 1.2;
        controls.keyPanSpeed = 0.8;
        this.camera.position.set(0, 5, 10)
        this.camera.lookAt(0, 0, 0)
        
        this.addedObjects = this.props.data.map((data: any, i: number) => this.createMesh(data, i))
        this.scene.add(...this.addedObjects)

        // https://storage.googleapis.com/models_gmfyit/chair.glb

        /*let gltfLoader = new GLTFLoader()
        let url = 'https://storage.googleapis.com/models_gmfyit/chair.glb'
        gltfLoader.load(url, (gltf) => {
            this.scene!.add(gltf.scene);
        });*/

        let ambientLight = new THREE.AmbientLight(0x555555, 2.0)
        this.scene.add(ambientLight)

        let light = new THREE.DirectionalLight(0x333333, 4.0)
        light.position.set(10, 10, 10)
        this.scene.add(light)

        this.renderer.render(this.scene, this.camera);

        this.animate()

    }

    animate = () => {
        this.frameId = window.requestAnimationFrame(this.animate);
        this.renderer!.render(this.scene!, this.camera!);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.frameId);
        if (this.mount != null) {
            this.mount.removeChild(this.renderer!.domElement);
        }
    }

    createMesh(data: any, index: number): THREE.Mesh {
        if (data.type == 'sphere') {
            return this.createSphere(index, data.color, data.radius, data.x, data.y, data.z)
        } else if (data.type == 'cone') {
            return this.createCone(index, data.color, data.radius, data.height, data.x, data.y, data.z, data.rotationX, data.rotationY, data.rotationZ)
        } else if (data.type == 'cylinder') {
            return this.createCylinder(index, data.color, data.radiusTop, data.radiusBottom, data.height, data.x, data.y, data.z, data.rotationX, data.rotationY, data.rotationZ)
        } else {
            return this.createCube(index, data.color, data.width, data.height, data.depth, data.x, data.y, data.z, data.rotationX, data.rotationY, data.rotationZ)
        }
    }

    createCylinder(index: number, color: number, radiusTop: number, radiusBottom: number, height: number, x: number, y: number, z: number, rotationX: number, rotationY: number, rotationZ: number): THREE.Mesh {
        var geometry = new THREE.CylinderGeometry(radiusTop, radiusBottom, height, 32)
        let material = new THREE.MeshStandardMaterial({ color: color, transparent: false, opacity: 1 })
        let cylinder = new THREE.Mesh(geometry, material)
        cylinder.position.set(x, y, z)
        cylinder.rotation.x = rotationX * Math.PI / 180
        cylinder.rotation.y = rotationY * Math.PI / 180
        cylinder.rotation.z = rotationZ * Math.PI / 180
        cylinder.name = index.toString()
        return cylinder
    }

    createCone(index: number, color: number, radius: number, height: number, x: number, y: number, z: number, rotationX: number, rotationY: number, rotationZ: number): THREE.Mesh {
        let geometry = new THREE.ConeBufferGeometry(radius, height)
        let material = new THREE.MeshStandardMaterial({ color: color, transparent: false, opacity: 1 })
        let cone = new THREE.Mesh(geometry, material)
        cone.position.set(x, y, z)
        cone.rotation.x = rotationX * Math.PI / 180
        cone.rotation.y = rotationY * Math.PI / 180
        cone.rotation.z = rotationZ * Math.PI / 180
        cone.name = index.toString()
        return cone
    }

    createSphere(index: number, color: number, radius: number, x: number, y: number, z: number): THREE.Mesh {
        let geometry = new THREE.SphereBufferGeometry(radius, 20, 20)
        let material = new THREE.MeshStandardMaterial({ color: color, transparent: false, opacity: 1 })
        let sphere = new THREE.Mesh(geometry, material)
        sphere.position.set(x, y, z)
        sphere.name = index.toString()
        return sphere
    }

    createCube(index: number, color: number, width: number, height: number, depth: number, x: number, y: number, z: number, rotationX: number, rotationY: number, rotationZ: number): THREE.Mesh {
        let geometry = new THREE.BoxBufferGeometry(width, height, depth)
        let material = new THREE.MeshStandardMaterial({ color: color, transparent: false, opacity: 1 })
        let cube = new THREE.Mesh(geometry, material)
        cube.position.set(x, y, z)
        cube.rotation.x = rotationX * Math.PI / 180
        cube.rotation.y = rotationY * Math.PI / 180
        cube.rotation.z = rotationZ * Math.PI / 180
        cube.name = index.toString()
        return cube
    }

    render() {
        return (
            <div
                id="boardCanvas"
                style={{ width: "100vw", height: "60vh" }}
                ref={mount => { if (mount != null) this.init(mount) }}
            />
        );
    }
}
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

import * as ROUTES from '../../constants/routes'
import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import User from '../../models/User';
import TokenHelper from '../../auth/TokenHelper';
import ScoreService from '../../services/scoreService';
import SubModule, { SubModuleTypes } from '../../models/SubModule';
import { DeckSubModuleFinishedReport, QuizQuestionFinishedReport } from '../../models/SubModuleFinishedReport';
import { DeckCardTypes } from '../../models/DeckCards';
import { QuestionCardReport, WordSearchCardReport } from '../../models/DeckCardsReport';
import { QuestionAnswer } from '../../models/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSortAlphaUp, faAlignJustify, faTrophy, faListOl, faChartLine, faRedo } from '@fortawesome/free-solid-svg-icons';
import { POINTS_PER_ANSWER_DECK } from '../../constants/ScoreConstants'
import { UserMaxScoreInSubModule } from '../../models/Scores';
import { DefaultQuestionReport } from '../../models/QuizQuestionsReport';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company?: Company;
    user?: User;
    subModuleId?: string;
    subModuleType?: string;
    userMaxScore?: UserMaxScoreInSubModule
    deckSubModuleFinishedReport?: DeckSubModuleFinishedReport;
    quizQuestionFinishedReport?: QuizQuestionFinishedReport
    subModule?: SubModule;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    subModuleId: undefined,
    subModuleType: undefined,
    deckSubModuleFinishedReport: undefined,
    subModule: undefined
};

interface Props extends RouteComponentProps { }

class GameResultScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData && userData) {
            let company = new Company(companyData)
            let user = new User(userData)
            let queryValues = queryString.parse(this.props.location.search)
            if (queryValues && queryValues.subModuleId && queryValues.subModuleType) {
                let subModuleId = queryValues.subModuleId as string
                let subModuleType = queryValues.subModuleType as string
                this.setState({ company, user, subModuleId, subModuleType }, () => this.loadResultScreenData(subModuleId, subModuleType))
            } else {
                this.setState({ error: 'Missing moduleId in query.' })
            }
        } else {
            this.setState({ error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }

    onExit = () => {
        this.props.history.goBack()
    }

    goToRanking = (moduleId: string) => {
        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.MODULE_RANKING}?moduleId=${moduleId}`)
    }

    async loadResultScreenData(subModuleId: string, subModuleType: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            let scoreReportRes = await scoreService.getUserLastScore(token, config.endpoint, subModuleId, subModuleType)
            let scoreReport = scoreReportRes.lastReport
            let subModule = scoreReportRes.subModule
            let userMaxScore = scoreReportRes.userMaxScore
            if (scoreReport) {
                if (subModuleType == SubModuleTypes.DECK) {
                    let deckSubModuleFinishedReport = scoreReport as DeckSubModuleFinishedReport
                    this.setState({ isLoading: false, deckSubModuleFinishedReport, subModule, userMaxScore })
                } else if (subModuleType == SubModuleTypes.QUIZ) {
                    let quizQuestionFinishedReport = scoreReport as QuizQuestionFinishedReport
                    this.setState({ isLoading: false, quizQuestionFinishedReport, subModule, userMaxScore })
                }
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadResultScreenData(subModuleId, subModuleType)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderQuizQuestionReport(quizQuestionFinishedReport: QuizQuestionFinishedReport) {
        return (<div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {quizQuestionFinishedReport.quizQuestionsReport.map(quizQuestionReport => {
                if (quizQuestionReport instanceof DefaultQuestionReport) {
                    let defaultQuestionReport = quizQuestionReport as DefaultQuestionReport
                    if (defaultQuestionReport.questionAnswer == QuestionAnswer.CorrectAnswer) {
                        return (<Row key={quizQuestionReport.quizQuestion.id}>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{defaultQuestionReport.quizQuestion.question}</div>
                                        <div style={{ backgroundColor: '#1d3256', height: 1, marginTop: 5, marginBottom: 5 }} />
                                        <div style={{ color: 'black', fontFamily: 'Montserrat', textAlign: 'start' }}>{`${defaultQuestionReport.timeToAnswer!} segundos +${45 - defaultQuestionReport.timeToAnswer!} pontos`}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>)
                    } else {
                        let answerStatus = defaultQuestionReport.questionAnswer == QuestionAnswer.WrongAnswer ? 'Errou' : (defaultQuestionReport.questionAnswer == QuestionAnswer.Timeout ? 'Tempo Esgotado' : 'Não Respondeu')
                        return (<Row key={quizQuestionReport.quizQuestion.id}>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{defaultQuestionReport.quizQuestion.question}</div>
                                        <div style={{ backgroundColor: '#1d3256', height: 1, marginTop: 5, marginBottom: 5 }} />
                                        <div style={{ color: 'black', fontFamily: 'Montserrat', textAlign: 'start' }}>{answerStatus}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>)
                    }
                } else {
                    return (<div key={quizQuestionReport.quizQuestion.id}></div>)
                }
            })}
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faTrophy} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Pontuação: ${quizQuestionFinishedReport.score}`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>)
    }

    renderDeckReport(deckReport: DeckSubModuleFinishedReport) {
        let questionCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type == DeckCardTypes.Question
        }).map(data => data as QuestionCardReport)
        let wordSearchCardReports = deckReport.deckCardsReport.filter(deckCardReport => {
            return deckCardReport.card.type == DeckCardTypes.WordSearch
        }).map(data => data as WordSearchCardReport)

        let totalQuestions = questionCardReports.length
        let correctAnsweredQuestions = questionCardReports.filter(qCardReport => qCardReport.questionAnswer == QuestionAnswer.CorrectAnswer).length

        let totalWordSearch = wordSearchCardReports.length
        let wordsFound = wordSearchCardReports.filter(wCardReport => wCardReport.wordFound).length

        return (<div>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faSortAlphaUp} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Você encontrou ${wordsFound} de ${totalWordSearch} palavras.`}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`+${wordsFound * POINTS_PER_ANSWER_DECK} pontos`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faAlignJustify} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Você acertou ${correctAnsweredQuestions} de ${totalQuestions} perguntas.`}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`+${correctAnsweredQuestions * POINTS_PER_ANSWER_DECK} pontos`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faTrophy} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Pontuação: ${(correctAnsweredQuestions + wordsFound) * POINTS_PER_ANSWER_DECK}`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>)
    }

    renderUserMaxScore(userMaxScore: UserMaxScoreInSubModule) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                    <div><FontAwesomeIcon color='#1d3256' icon={faChartLine} size='2x' /></div>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Sua maior pontuação neste nível foi: ${userMaxScore.score}`}</div>
                    </div>
                </div>
            </Col>
        </Row>)
    }

    renderHeader(subModule: SubModule) {
        return (<Row>
            <Col className="d-flex flex-column justify-content-center relative" style={{ marginTop: 10 }} md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center">
                    <img style={{ height: '10vh', marginBottom: 5, borderRadius: 5 }} src={subModule.pic} />
                </div>
                <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontStyle: 'bold', verticalAlign: 'middle', textAlign: 'center' }}>{subModule.title}</div>
                <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                    <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                </div>
            </Col>
        </Row>)
    }

    renderRedo(subModule: SubModule) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 15, width: '100%' }}>
                    <Button style={{ padding: 5, width: '100%', background: '#1d3256' }} onClick={() => { 
                        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.GAME}?subModuleId=${subModule.id}&subModuleType=${subModule.type}`)
                     }}><FontAwesomeIcon icon={faRedo} /> Refazer</Button>
                </div>
            </Col>
        </Row>)
    }

    renderRanking(moduleId: string) {
        return (<Row>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 10, marginBottom: 5, width: '100%' }}>
                    <Button style={{ padding: 5, width: '100%', background: '#1d3256' }} onClick={() => { this.goToRanking(moduleId) }}><FontAwesomeIcon icon={faListOl} /> Ver Ranking</Button>
                </div>
            </Col>
        </Row>)
    }

    render() {

        let { company, error, isLoading, deckSubModuleFinishedReport, quizQuestionFinishedReport, subModule, userMaxScore } = this.state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        return (<div>
            {error && this.renderError(error)}
            <Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh', background: '#5c959e' }}>
                {subModule && this.renderHeader(subModule)}
                {deckSubModuleFinishedReport && this.renderDeckReport(deckSubModuleFinishedReport)}
                {quizQuestionFinishedReport && this.renderQuizQuestionReport(quizQuestionFinishedReport)}
                {userMaxScore && this.renderUserMaxScore(userMaxScore)}
                {subModule && this.renderRedo(subModule)}
                {subModule && this.renderRanking(subModule.moduleId)}
            </Container>
        </div>)
    }
}

const GameResultScreen = compose<Props, {}>(withRouter)(GameResultScreenBase)

export default GameResultScreen
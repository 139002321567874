const dev = {
    endpoint: 'https://lira-game.appspot.com',
    isProduction: false
}

const prod = {
    endpoint: 'https://lira-game.appspot.com',
    isProduction: true
}

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default config


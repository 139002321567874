import React, { Component, FunctionComponent, CSSProperties } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';

import * as ROUTES from '../../constants/routes'
import QuizQuestion, { QuizQuestionType, DefaultQuestion } from '../../models/QuizQuestion';
import { QuizQuestionsReport, DefaultQuestionReport } from '../../models/QuizQuestionsReport';
import { Question, QuestionAnswer } from '../../models/Question';
import * as _ from 'lodash'
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle, faCheckCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

const answerTime = 45

interface State {
    isNavBarMenuOpen: boolean,
    currentQuizQuestion: number,
    questions: Array<Question>,
    isTimerOn: boolean,
    timeInSeconds: number,
    start: number,
    selectedAnswer: number
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentQuizQuestion: 0,
    questions: [],
    isTimerOn: false,
    timeInSeconds: 0,
    start: 0,
    selectedAnswer: -1
};

interface Props { quizQuestions: Array<QuizQuestion>, subModuleId: string, onExit: () => void, onFinish: (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => void, companyColor: string | undefined, companyPic: string | undefined }

export default class QuizScreen extends Component<Props, State> {

    timer: any

    quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>
    startTime: number
    endTime: number | undefined

    constructor(props: Props) {
        super(props)
        this.startTime = (new Date()).getTime()
        this.quizQuestionsReport = []
        this.state = { ...INITIAL_STATE, questions: this.props.quizQuestions.map(question => { return new Question(question.id, QuestionAnswer.NotAnswered) }) }

        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    startTimer() {
        this.setState({
            isTimerOn: true,
            timeInSeconds: this.state.timeInSeconds,
            start: Date.now()
        })
        clearInterval(this.timer)
        this.timer = setInterval(() => { this.timerTick() }, 500);
    }

    stopTimer() {
        this.setState({ isTimerOn: false })
        clearInterval(this.timer)
    }

    resetTimer() {
        this.setState({ timeInSeconds: 0, isTimerOn: false })
    }

    timerTick() {
        let timeInSeconds = Math.round((Date.now() - this.state.start) / 1000)
        if (timeInSeconds <= answerTime) {
            this.setState({
                timeInSeconds: Math.round((Date.now() - this.state.start) / 1000)
            })
        } else {
            this.onQuestionTimeout()
            clearInterval(this.timer)
            this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
        }
    }

    getQuestion(quizQuestion: QuizQuestion) {
        let filter = this.state.questions.filter(question => { return question.id == quizQuestion.id })
        return filter[0]
    }

    addQuestionReport(quizQuestion: QuizQuestion) {
        if (this.quizQuestionsReport.filter(questionReport => questionReport.quizQuestion.id == quizQuestion.id).length > 0) return
        if (quizQuestion.type == QuizQuestionType.Default) {
            let defaultQuestionReport = new DefaultQuestionReport(quizQuestion as DefaultQuestion)
            this.quizQuestionsReport.push(defaultQuestionReport)
        }
    }

    onDefaultQuestionAnswerSelected = (answerNumber: number) => {
        let questions = this.state.questions
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as DefaultQuestion
        let currentQuestion = _.head(questions.filter(question => { return question.id == currentQuizQuestion.id }))
        if (currentQuestion) {
            if (currentQuestion.answer != QuestionAnswer.NotAnswered) return
            currentQuestion.selectedAnswer = answerNumber
            let cardReport = _.head(this.quizQuestionsReport.filter(quizQuestionReport => quizQuestionReport.quizQuestion.id == currentQuizQuestion.id))
            let questionCardReport = cardReport as DefaultQuestionReport
            questionCardReport.timeToAnswer = this.state.timeInSeconds
            if (answerNumber == currentQuizQuestion.correctAnswer) {
                currentQuestion.answer = QuestionAnswer.CorrectAnswer
                questionCardReport.questionAnswer = QuestionAnswer.CorrectAnswer
                if(this.state.currentQuizQuestion < this.props.quizQuestions.length - 1) {
                    setTimeout(() => {
                        this.setState({ currentQuizQuestion: this.state.currentQuizQuestion + 1, selectedAnswer: -1, timeInSeconds: 0 })
                        this.startTimer()
                    }, 2000)
                } else {
                    setTimeout(() => {
                        this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                    }, 2000)
                }
            } else {
                currentQuestion.answer = QuestionAnswer.WrongAnswer
                questionCardReport.questionAnswer = QuestionAnswer.WrongAnswer
                setTimeout(() => {
                    this.props.onFinish(this.startTime, (new Date()).getTime(), this.quizQuestionsReport)
                }, 2000)
            }
            clearInterval(this.timer)
            this.setState({ questions: [...this.state.questions], selectedAnswer: answerNumber, isTimerOn: false })
        }
    }

    onQuestionTimeout() {
        let questions = this.state.questions
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion] as DefaultQuestion
        let currentQuestion = _.head(questions.filter(question => { return question.id == currentQuizQuestion.id }))
        if(currentQuestion) {
            let cardReport = _.head(this.quizQuestionsReport.filter(quizQuestionReport => quizQuestionReport.quizQuestion.id == currentQuizQuestion.id))
            let questionCardReport = cardReport as DefaultQuestionReport
            currentQuestion.answer = QuestionAnswer.Timeout
            questionCardReport.questionAnswer = QuestionAnswer.Timeout
        }
    }

    renderHeader() {
        return (<Row>
            <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                <div style={{ alignSelf: 'center', marginTop: 10 }}>
                    <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                </div>
                <div style={{ flex: 1, marginLeft: 5 }}>
                    <div className="text-center" style={{ color: '#1d3256', fontFamily: 'Montserrat' }}>{`${answerTime - this.state.timeInSeconds} segundos`}</div>
                    <Progress color='dark' value={answerTime - this.state.timeInSeconds} max={answerTime} />
                </div>
            </Col>
        </Row>)
    }

    renderCurrentQuestion() {
        let currentQuizQuestion = this.props.quizQuestions[this.state.currentQuizQuestion]
        this.addQuestionReport(currentQuizQuestion)
        if(currentQuizQuestion instanceof DefaultQuestion) {
            return this.renderDefaultQuestion(currentQuizQuestion as DefaultQuestion)
        }
    }

    renderDefaultQuestion(defaultQuestion: DefaultQuestion) {
        let questions = this.state.questions
        let currentQuestion = _.head(questions.filter(question => { return question.id == defaultQuestion.id }))!
        if(currentQuestion.answer == QuestionAnswer.NotAnswered && !this.state.isTimerOn) this.startTimer()
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }} md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 5, textAlign: 'center', color: 'black', fontFamily: 'Montserrat' }}>{defaultQuestion.question}</div>
                    <AnswerComponent answerText={defaultQuestion.answer0} selectedAnswer={this.state.selectedAnswer} answerNumber={0}
                        questionAnswer={currentQuestion.answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} />
                    <AnswerComponent answerText={defaultQuestion.answer1} selectedAnswer={this.state.selectedAnswer} answerNumber={1}
                        questionAnswer={currentQuestion.answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} />
                    {defaultQuestion.answer2 && (
                        <AnswerComponent answerText={defaultQuestion.answer2} selectedAnswer={this.state.selectedAnswer} answerNumber={2}
                        questionAnswer={currentQuestion.answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} />
                    )}
                    {defaultQuestion.answer3 && (
                        <AnswerComponent answerText={defaultQuestion.answer3} selectedAnswer={this.state.selectedAnswer} answerNumber={3}
                        questionAnswer={currentQuestion.answer} onAnswerSelected={this.onDefaultQuestionAnswerSelected} />
                    )}
                </div>
            </Col>
        </Row>)
    }
    

    render() {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            {this.renderHeader()}
            {this.renderCurrentQuestion()}
        </Container>)
    }
}

interface AnswerComponentProps {
    answerText: string,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer
    onAnswerSelected: (answerNumber: number) => void
}

export class AnswerComponent extends Component<AnswerComponentProps> {

    constructor(props: AnswerComponentProps) {
        super(props)
    }
  
    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined) {
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={style}>
                <strong>{this.props.answerText}</strong> {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if(icon) return (<FontAwesomeIcon icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer == this.props.answerNumber) {
            if (this.props.questionAnswer == QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
            }
        } else {
            return this.renderDiv(questionDefaultStyle)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    flex: 1, textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, backgroundColor: 'white',
    paddingLeft: 2, paddingRight: 2, color: 'black', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionWrongAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2,
    paddingLeft: 2, paddingRight: 2, color: 'white', background: 'red', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionCorrectAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, paddingLeft: 2, paddingRight: 2,
    color: 'white', background: 'green', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}
import DeckCard, { QuestionCard, WordSearchCard, DeckCardTypes, getDeckCard } from "./DeckCards";
import { QuestionAnswer } from "./Question";

export class DeckCardReport<T extends DeckCard> {

    card: T
    enterTimes: Array<number>
    exitTimes: Array<number>
    
    constructor(card: T) {
        this.card = card
        this.enterTimes = []
        this.exitTimes = []
    }

    addEnterTime() {
        let time = (new Date()).getTime()
        this.enterTimes.push(time)
    }

    addExitTime() {
        let time = (new Date()).getTime()
        this.exitTimes.push(time)
    }

    getData(): any {
        return {
            card: this.card.getData(),
            enterTimes: this.enterTimes,
            exitTimes: this.exitTimes,
        }
    }
}

export class QuestionCardReport extends DeckCardReport<QuestionCard> {
    questionAnswer: QuestionAnswer
    answerTime?: number
    constructor(card: QuestionCard) {
        super(card)
        this.questionAnswer = QuestionAnswer.NotAnswered
    }

    getData(): any {
        let data = {
            ...super.getData(),
            questionAnswer: this.questionAnswer
        }
        if(this.answerTime) data.answerTime = this.answerTime
        return data
    }
}

export class WordSearchCardReport extends DeckCardReport<WordSearchCard> {
    foundTime?: number
    wordFound: boolean
    constructor(card: WordSearchCard) {
        super(card)
        this.wordFound = false
    }

    getData(): any {
        let data = {
            ...super.getData(),
            wordFound: this.wordFound
        }
        if(this.foundTime) data.foundTime = this.foundTime
        return data
    }
}

export function getCardReportArray(dataArray: Array<any>): Array<DeckCardReport<DeckCard>> {
    let array: Array<DeckCardReport<DeckCard>> = []
    for(let i = 0; i < dataArray.length; i++) {
        let data = dataArray[i]
        let enterTimes = data.enterTimes
        let exitTimes = data.exitTimes
        if(data.card.type == DeckCardTypes.Question) {
            let questionCard = getDeckCard(data.card) as QuestionCard
            let questionCardReport = new QuestionCardReport(questionCard)
            questionCardReport.enterTimes = enterTimes
            questionCardReport.exitTimes = exitTimes
            questionCardReport.questionAnswer = data.questionAnswer
            questionCardReport.answerTime = data.answerTime
            array.push(questionCardReport)
        } else if(data.card.type == DeckCardTypes.WordSearch) {
            let wordSearchCard = getDeckCard(data.card) as WordSearchCard
            let wordSearchCardReport = new WordSearchCardReport(wordSearchCard)
            wordSearchCardReport.enterTimes = enterTimes
            wordSearchCardReport.exitTimes = exitTimes
            wordSearchCardReport.foundTime = data.foundTime
            wordSearchCardReport.wordFound = data.wordFound
            array.push(wordSearchCardReport)
        } else {
            let card = getDeckCard(data.card)
            let deckCardReport = new DeckCardReport(card)
            deckCardReport.enterTimes = enterTimes
            deckCardReport.exitTimes = exitTimes
            array.push(deckCardReport)
        }
    }
    return array
}


import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, Button, Alert, FormGroup, Label, Form, Progress } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import LoadingScreen from '../../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowCircleRight, faListOl } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../../constants/routes'
import User from '../../../models/User';
import ContentService from '../../../services/contentService';
import TokenHelper from '../../../auth/TokenHelper';
import Module from '../../../models/Module';
import { UserMaxScoreInSubModule } from '../../../models/Scores';
import SubModule from '../../../models/SubModule';
import config from '../../../config'

interface State {
    error: string | undefined;
    isLoading: boolean;
    user: User | undefined;
    modules: Array<Module>
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    user: undefined,
    modules: [],
};

interface Props extends RouteComponentProps { }

class MainScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        this.loadModules('-gmfyit')
    }

    async loadModules(companyId: string) {
        this.setState({ isLoading: true, error: undefined })
        try {
            let contentService = new ContentService()
            let modules = await contentService.getOpenCompanyModules(config.endpoint, companyId)
            this.setState({ modules, isLoading: false })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    renderError(error: string) {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    renderModules(modules: Array<Module>) {
        return modules.map(module => {
            return (<div key={module.id} className="d-flex flex-column" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', marginBottom: 5, marginTop: 5 }}>
                <div className="d-flex justify-content-center align-items-center">
                    <img style={{ height: 50, width: 50, minWidth: 50, borderRadius: 5, margin: 5 }} src={module.pic} />
                    <div style={{ color: '#1d3256', fontSize: 20, fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>{module.title}</div>
                </div>
                <div style={{ height: 1, background: '#1d3256', marginLeft: 5, margin: 5 }} />
                <div className="d-flex align-items-center">
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 14, verticalAlign: 'middle', textAlign: 'center', marginLeft: 5 }}>{module.description}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Button style={{ background: '#5c959e', margin: 10 }} block onClick={() => { this.props.history.push(`${ROUTES.TEST}${ROUTES.SUBMODULE_SELECT}?moduleId=${module.id}`) }}>Selecionar <FontAwesomeIcon icon={faArrowCircleRight} /></Button>
                </div>
            </div>)
        })
    }

    renderHeader() {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ height: '20vh', marginBottom: 5 }} src={'https://companies-logo-gmfy.s3-us-west-2.amazonaws.com/gmfyit_logo_512.png'} />
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>)
    }

    renderMainContent(modules: Array<Module>) {
        return (
            <Row style={{ flex: 1, overflow: 'auto' }}>
                <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}>
                    {this.renderModules(modules)}
                </Col>
            </Row>)
    }

    render() {

        let { error, isLoading, modules, } = this.state

        if (isLoading) { return <LoadingScreen image={'https://companies-logo-gmfy.s3-us-west-2.amazonaws.com/gmfyit_logo_512.png'} /> }

        return(<Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            {error && this.renderError(error)}
            {this.renderHeader()}
            {modules && this.renderMainContent(modules)}
        </Container>)
    }

}

const MainScreen = compose<Props, {}>(withRouter)(MainScreenBase)

export default MainScreen
import User from './User'

export class RankingEntry {

    user: User
    score: number

    constructor(user: User, score: number) {
        this.user = user
        this.score = score
    }

    getData(): any {
        return {
            user: this.user.getData(),
            score: this.score
        }
    }

}
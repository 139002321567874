import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

import * as ROUTES from '../../../constants/routes'
import LoadingScreen from '../../loading'
import Cookies from 'universal-cookie';
import Company from '../../../models/Company';
import config from '../../../config'
import User from '../../../models/User';
import TokenHelper from '../../../auth/TokenHelper';
import ScoreService from '../../../services/scoreService';
import SubModule, { SubModuleTypes } from '../../../models/SubModule';
import { DeckSubModuleFinishedReport } from '../../../models/SubModuleFinishedReport';
import { DeckCardTypes } from '../../../models/DeckCards';
import { QuestionCardReport, WordSearchCardReport } from '../../../models/DeckCardsReport';
import { QuestionAnswer } from '../../../models/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSortAlphaUp, faAlignJustify, faTrophy, faListOl, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { POINTS_PER_ANSWER_DECK } from '../../../constants/ScoreConstants'
import { UserMaxScoreInSubModule } from '../../../models/Scores';
import ContentService from '../../../services/contentService';

interface State {
    error: string | undefined;
    isLoading: boolean;
    subModule?: SubModule;
    totalQuestions: number;
    correctQuestions: number;
    totalWordSearch: number;
    wordsFound: number;
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    subModule: undefined,
    totalQuestions: 0,
    correctQuestions: 0,
    totalWordSearch: 0,
    wordsFound: 0,
};

interface Props extends RouteComponentProps { subModuleId: string, }

class GameResultScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let queryValues = queryString.parse(this.props.location.search)
        console.log('query values')
        console.log(queryValues)
        if (queryValues && queryValues.subModuleId) {
            let subModuleId = queryValues.subModuleId as string
            let totalQuestions = this.convertValueToNumber(queryValues.totalQuestions)
            let correctQuestions = this.convertValueToNumber(queryValues.correctQuestions)
            let totalWordSearch = this.convertValueToNumber(queryValues.totalWordSearch)
            let wordsFound = this.convertValueToNumber(queryValues.wordsFound)
            this.setState({ totalQuestions, correctQuestions, totalWordSearch, wordsFound }, () => this.loadSubModule(subModuleId))
        } else {
            this.setState({ error: 'Missing subModuleId in query.' })
        }
    }

    convertValueToNumber(val: string | string[] | undefined | null): number {
        return val ? +(val as string) : 0
    }

    onExit = () => {
        this.props.history.goBack()
    }

    async loadSubModule(subModuleId: string) {
        this.setState({ isLoading: true, error: undefined })
        try {
            let contentService = new ContentService()
            let subModule = await contentService.getOpenCompanySubModule(config.endpoint, subModuleId)
            this.setState({ isLoading: false, subModule })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderDeckReport(subModule: SubModule, totalQuestions: number, correctQuestions: number, totalWordSearch: number, wordsFound: number) {

        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" style={{ marginTop: 10 }} md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ height: '10vh', marginBottom: 5, borderRadius: 5 }} src={subModule.pic} />
                    </div>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontStyle: 'bold', verticalAlign: 'middle', textAlign: 'center' }}>{subModule.title}</div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faSortAlphaUp} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Você encontrou ${wordsFound} de ${totalWordSearch} palavras.`}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`+${wordsFound * POINTS_PER_ANSWER_DECK} pontos`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faAlignJustify} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Você acertou ${correctQuestions} de ${totalQuestions} perguntas.`}</div>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`+${correctQuestions * POINTS_PER_ANSWER_DECK} pontos`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div><FontAwesomeIcon color='#1d3256' icon={faTrophy} size='2x' /></div>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Pontuação: ${(correctQuestions + wordsFound) * POINTS_PER_ANSWER_DECK}`}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    render() {

        let { error, isLoading, subModule, totalQuestions, correctQuestions, totalWordSearch, wordsFound } = this.state

        let companyPic = 'https://companies-logo-gmfy.s3-us-west-2.amazonaws.com/gmfyit_logo_512.png'
        if (isLoading) { return <LoadingScreen image={companyPic} /> }

        return (<div>
            {error && this.renderError(error)}
            {subModule && this.renderDeckReport(subModule, totalQuestions, correctQuestions, totalWordSearch, wordsFound)}
        </div>)
    }
}

const GameResultScreen = compose<Props, {}>(withRouter)(GameResultScreenBase)

export default GameResultScreen
export const LANDING = '/'
export const PRODUCTS = '/products'
export const CONTACT = '/contact'
export const LEADERSHIP = '/leadership'
export const TIME_ADMIN = '/timeadmin'
export const TEAM_WORK = '/teamwork'
export const TEST = '/test'
export const LOADING = '/loading'
export const APP_LANDING = '/app'
export const ENTER = '/enter'
export const LOGIN = '/login'
export const SIGN_UP = '/signup'
export const MAIN = '/main'
export const SUBMODULE_SELECT = '/submodule'
export const GAME = '/game'
export const RESULT = '/result'
export const MODULE_RANKING = '/module_ranking'
export const COMPANY_RANKING = '/company_ranking'
export const NEW_PWD = '/new_pwd'
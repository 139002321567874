import axios from 'axios'
import Company, { SignUpConfig } from '../models/Company'
import User from '../models/User'

export default class EnterService {
    getCompanySignUpConfig = async (mainUrl: string, companyId: string): Promise<SignUpConfigRes> => {
        let url = `${mainUrl}/auth/companySignUpConfig/${companyId}`
        let result = await axios.get(url)
        return new SignUpConfigRes(result.data.data)
    }

    getUserExists = async (mainUrl: string, primaryProperty: string, value: string): Promise<boolean> => {
        let url = `${mainUrl}/auth/userExists`
        let result = await axios.post(url, { primaryProperty, value })
        return result.data.data.userExists
    }

    createUser = async (mainUrl: string, companyId: string, signUpData: any) => {
        let url = `${mainUrl}/auth/signup`
        let result = await axios.post(url, { companyId, signUpData })
        return new UserRes(result.data.data)
    }

    signInUser = async (mainUrl: string, companyId: string, signInData: any) => {
        let url = `${mainUrl}/auth/signin`
        let result = await axios.post(url, { companyId, signInData })
        return new UserRes(result.data.data)
    }

    getCompanies = async(mainUrl: string) => {
        let url = `${mainUrl}/auth/companies`
        let result = await axios.get(url)
        return result.data.data.companies.map((data: any) => { return new Company(data) })
    }
}

export class UserRes {
    user: User
    tokenData: TokenData
    constructor(data: any) {
        this.user = new User(data.user)
        this.tokenData = new TokenData(data.tokenData)
    }
}

export class TokenData {
    token: string
    refreshToken: string
    constructor(data: any) {
        this.token = data.token
        this.refreshToken = data.refreshToken
    }
}

export class SignUpConfigRes {
    company: Company
    signUpConfig: SignUpConfig
    constructor(data: any) {
        this.company = new Company(data.company)
        this.signUpConfig = new SignUpConfig(data.signUpConfig)
    }
}
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, Button, Alert, FormGroup, Label, Form, Progress } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowCircleRight, faListOl } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes'
import User from '../../models/User';
import ContentService from '../../services/contentService';
import TokenHelper from '../../auth/TokenHelper';
import Module from '../../models/Module';
import { UserMaxScoreInSubModule } from '../../models/Scores';
import SubModule from '../../models/SubModule';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    user: User | undefined;
    modules: Array<Module>
    companySubModules: Array<SubModule>
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    modules: [],
    companySubModules: [],
    userMaxScoreInSubModules: []
};

interface Props extends RouteComponentProps { }

class MainScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData && userData) {
            let company = new Company(companyData)
            let user = new User(userData)
            this.setState({ company, user })
            this.loadModules(company.id)
        } else {
            this.setState({ error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }

    async loadModules(companyId: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let res = await contentService.getCompanyModules(token, config.endpoint, companyId)
            let { modules, companySubModules, userMaxScoreInSubModules } = res
            this.setState({ modules, companySubModules, userMaxScoreInSubModules, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadModules(companyId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    goToCompanyRanking = () => {
        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.COMPANY_RANKING}`)
    }

    logout = (companyId: string) => {
        let cookies = new Cookies()
        cookies.remove('user', { path: '/' })
        cookies.remove('token', { path: '/' })
        cookies.remove('refreshToken', { path: '/' })
        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${companyId}`)
    }

    renderError(error: string) {
        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>

        );
    }

    renderModules(company: Company, modules: Array<Module>, companySubModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>) {
        let sortedModulesByPosition = modules.sort((a, b) => { return a.pos - b.pos })
        let modulesStatus = sortedModulesByPosition.map(module => {
            let finishedSubModules = userMaxScoreInSubModules.filter(data => data.moduleId == module.id).length
            let moduleSubModules = companySubModules.filter(data => data.moduleId == module.id).length
            return { finishedSubModules, moduleSubModules, module }
        })
        return modulesStatus.map(({ finishedSubModules, moduleSubModules, module }, index) => {
            return (<div key={module.id} className="d-flex flex-column" style={{ boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)', marginBottom: 5, marginTop: 5 }}>
                <div className="d-flex justify-content-center align-items-center">
                    <img style={{ height: 50, width: 50, minWidth: 50, borderRadius: 5, margin: 5 }} src={module.pic} />
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>{module.title}</div>
                </div>
                <div style={{ height: 1, background: '#1d3256', marginLeft: 5, margin: 5 }} />
                <Progress animated color='warning' style={{ marginLeft: 5, marginRight: 5 }}  value={finishedSubModules} max={moduleSubModules}>{`Níveis finalizados: ${finishedSubModules} de ${moduleSubModules}`}</Progress>
                {this.renderPlayButton(module, index, company.mainColor)}
            </div>)
        })
    }

    renderPlayButton(module: Module, index: number, companyMainColor: string | undefined) {
        // TODO: criar regra para permitir o usuario so fazer a proxima missao quando terminar a anterior
        return (<div className="d-flex justify-content-center align-items-center">
        <Button style={{ background: companyMainColor || '#000', margin: 10 }} block onClick={() => { this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.SUBMODULE_SELECT}?moduleId=${module.id}`) }}>Selecionar <FontAwesomeIcon icon={faArrowCircleRight} /></Button>
    </div>)
    }

    renderHeader(company: Company) {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ height: '20vh', marginBottom: 5 }} src={company.pic} />
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.logout(company.id) }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>)
    }

    renderMainContent(company: Company, modules: Array<Module>, companySubModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>) {
        return (
            <Row style={{ flex: 1, overflow: 'auto' }}>
                <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}>
                    {this.renderModules(company, modules, companySubModules, userMaxScoreInSubModules)}
                </Col>
            </Row>)
    }

    renderBottomNavBar(company: Company) {

        return (<Row style={{ marginTop: 5, marginBottom: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
                    <Button style={{ padding: 5, width: '100%', background: '#343a40', boxShadow: '0 5px 9px 0 rgba(0,0,0,0.4)' }} onClick={() => { this.goToCompanyRanking() }}><FontAwesomeIcon icon={faListOl} />{` Ver Ranking ${company.name}`}</Button>
                </div>
            </Col>
        </Row>)
    }

    render() {

        let { company, error, isLoading, modules, companySubModules, userMaxScoreInSubModules } = this.state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        return(<Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            {error && this.renderError(error)}
            {company && this.renderHeader(company)}
            {modules && this.renderMainContent(company!, modules, companySubModules, userMaxScoreInSubModules)}
            {company && this.renderBottomNavBar(company)}
        </Container>)
    }

}

const MainScreen = compose<Props, {}>(withRouter)(MainScreenBase)

export default MainScreen
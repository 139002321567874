import React, { Component, CSSProperties } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { QuestionAnswer } from '../../models/Question'

interface AnswerComponentProps {
    answerText: string,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer,
    correctAnswer: number,
    onAnswerSelected: (answerNumber: number) => void
}

export default class AnswerComponent extends Component<AnswerComponentProps> {

    constructor(props: AnswerComponentProps) {
        super(props)
    }

    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined) {
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={style}>
                <strong>{this.props.answerText}</strong> {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if (icon) return (<FontAwesomeIcon icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer == this.props.answerNumber) {
            if (this.props.questionAnswer == QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
            }
        } else if (this.props.questionAnswer == QuestionAnswer.WrongAnswer && this.props.answerNumber == this.props.correctAnswer) {
            return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
        } else {
            return this.renderDiv(questionDefaultStyle)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    flex: 1, textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2,
    paddingLeft: 2, paddingRight: 2, color: 'black', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionWrongAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2,
    paddingLeft: 2, paddingRight: 2, color: 'white', background: 'red', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}

const questionCorrectAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, paddingLeft: 2, paddingRight: 2,
    color: 'white', background: 'green', boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)'
}
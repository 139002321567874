export default class WordSearchManager {

    rows: number
    columns: number

    constructor(rows: number, columns: number) {
        this.rows = rows
        this.columns = columns
    }

    setForDirection(word: String, direction: WordDirection): Array<Array<boolean>> {
        switch (direction) {
            case WordDirection.HORIZONTAL: return this.setHorizontalWord(word)
            default: return this.setVerticalWord(word)
        }
    }

    initWord(word: string): Array<Array<boolean>> {
        return this.setForDirection(word, this.getRandomDirection())
    }

    setHorizontalWord(word: String): Array<Array<boolean>> {
        let offset = this.rows - word.length
        let startPoint = offset > 0 ? Math.round(Math.random() * (offset - 1)): 0 //Random().nextInt(offset + 1) : 0
        let column = Math.floor(Math.random() * this.columns)  //Random().nextInt(columns)
        let result = this.createFalseMatrix(this.columns, this.rows) // matrix2d(columns, rows, { false })
        for (let i = startPoint; i < (startPoint + word.length); i++) {
            result[column][i] = true
        }
        return result
    }

    setVerticalWord(word: String): Array<Array<boolean>> {
        let offset = this.columns - word.length
        let startPoint = offset > 0 ? Math.round(Math.random() * (offset - 1)): 0
        let row = Math.floor(Math.random() * this.rows)
        let result = this.createFalseMatrix(this.columns, this.rows)
        for (let i = startPoint; i < (startPoint + word.length); i++) {
            result[i][row] = true
        }
        return result
    }

    createFalseMatrix(columns: number, rows: number): Array<Array<boolean>> {
        let matrix: Array<Array<boolean>> = []
        for(let column = 0; column < columns; column++) {
            matrix[column] = [] 
            for(let row = 0; row < rows; row++) {
                matrix[column][row] = false
            }
        }
        return matrix
    }

    getRandomDirection(): WordDirection {
        let next = Math.random()
        if(next < 0.5) return WordDirection.HORIZONTAL
        else return WordDirection.VERTICAL
    }
}

export enum WordDirection {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL'
}
import axios from 'axios'
import Module from '../models/Module'
import SubModule from '../models/SubModule'
import DeckCard, { getDeckCard } from '../models/DeckCards'
import QuizQuestion, { getQuizQuestion } from '../models/QuizQuestion'
import { UserMaxScoreInSubModule } from '../models/Scores'

export default class ContentService {

    async getOpenCompanyModules(mainUrl: string, companyId: string): Promise<Array<Module>> {
        let url = `${mainUrl}/open/content/modules/${companyId}`
        let result = await axios.get(url)
        let modules = result.data.data.companyModules.map((data: any) => new Module(data))
        return modules
    }

    async getCompanyModules(token: string, mainUrl: string, companyId: string): Promise<CompanyModulesRes> {
        let url = `${mainUrl}/mobile/content/modules/user/${companyId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let res = new CompanyModulesRes(result.data.data)
        return res
    }

    async getCompanyModule(token: string, mainUrl: string, companyId: string, moduleId: string): Promise<Module> {
        let url = `${mainUrl}/mobile/content/module/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let module = new Module(result.data.data.module)
        return module
    }

    async getOpenCompanySubModule(mainUrl: string, subModuleId: string): Promise<SubModule> {
        let url = `${mainUrl}/open/content/subModule/${subModuleId}`
        let result = await axios.get(url)
        let subModule = new SubModule(result.data.data.subModule)
        return subModule
    }

    async getCompanyModuleAndSubmodules(token: string, mainUrl: string, companyId: string, moduleId: string) {
        let url = `${mainUrl}/mobile/content/moduleAndSubModules/user/${companyId}/${moduleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let moduleAndSubmoduleRes = new ModuleAndSubmoduleRes(result.data.data)
        return moduleAndSubmoduleRes
    }

    async getOpenCompanyModuleAndSubmodules(mainUrl: string, companyId: string, moduleId: string): Promise<OpenModuleAndSubmoduleRes> {
        let url = `${mainUrl}/open/content/moduleAndSubModules/${companyId}/${moduleId}`
        let result = await axios.get(url)
        let moduleAndSubmoduleRes = new OpenModuleAndSubmoduleRes(result.data.data)
        return moduleAndSubmoduleRes
    }

    async getDeckCards(token: string, mainUrl: string, subModuleId: string): Promise<Array<DeckCard>> {
        let url = `${mainUrl}/mobile/content/deckCards/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` } })
        let deckCards = result.data.data.deckCards.map((data: any) => getDeckCard(data))
        return deckCards
    }

    async getOpenDeckCards(mainUrl: string, subModuleId: string): Promise<Array<DeckCard>> {
        let url = `${mainUrl}/open/content/deckCards/${subModuleId}`
        let result = await axios.get(url)
        let deckCards = result.data.data.deckCards.map((data: any) => getDeckCard(data))
        return deckCards
    }

    async getQuizQuestions(token: string, mainUrl: string, subModuleId: string): Promise<Array<QuizQuestion>> {
        let url = `${mainUrl}/mobile/content/quizQuestions/${subModuleId}`
        let result = await axios.get(url, { headers: { 'Authorization': `Token ${token}` }})
        let quizQuestions = result.data.data.quizQuestions.map((data: any) => getQuizQuestion(data))
        return quizQuestions
    }
}

export class CompanyModulesRes {
    modules: Array<Module>
    companySubModules: Array<SubModule>
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    
    constructor(data: any) {
        this.modules = data.companyModules.map((data: any) => new Module(data))
        this.companySubModules = data.companySubModules.map((data: any) => new SubModule(data))
        this.userMaxScoreInSubModules = data.userMaxScoreInSubModules.map((data: any) => new UserMaxScoreInSubModule(data))
    }
}

export class ModuleAndSubmoduleRes {
    module: Module
    subModules: Array<SubModule>
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((subModuleData: any) => new SubModule(subModuleData))
        this.userMaxScoreInSubModules = data.userMaxScoreInSubModules.map((data: any) => new UserMaxScoreInSubModule(data))
    }
}

export class OpenModuleAndSubmoduleRes {
    module: Module
    subModules: Array<SubModule>
    constructor(data: any) {
        this.module = new Module(data.module)
        this.subModules = data.subModules.map((subModuleData: any) => new SubModule(subModuleData))
    }
}
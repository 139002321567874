import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, Button, Alert, Form, FormGroup, Label } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

import * as ROUTES from '../../constants/routes'
import Company, { SignUpConfig, DocConfig, EmailConfig } from '../../models/Company';
import Cookies from 'universal-cookie';
import EnterService from '../../services/enterService';
import config from '../../config'
import LoadingScreen from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface State {
    isLoading: boolean
    error: any | undefined
    signUpConfig: SignUpConfig | undefined,
    email: string | undefined;
    doc: string | undefined,
    company: Company | undefined
    password: string
};

const INITIAL_STATE: State = {
    isLoading: true,
    error: null,
    signUpConfig: undefined,
    email: undefined,
    doc: undefined,
    company: undefined,
    password: ''
};

interface Props extends RouteComponentProps {  }

class LoginScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        if(companyData) {
            let company = new Company(companyData)
            this.setState({ company })
            this.loadCompanySignUpConfig(company.id)
        } else {
            this.setState({ error: 'Ops, link invalido. Por favor escolha uma empresa.' })
        }
    }

    async loadCompanySignUpConfig(companyId: string) {
        try {
            this.setState({ isLoading: true, error: undefined })
            let enterService = new EnterService()
            let { company, signUpConfig } = await enterService.getCompanySignUpConfig(config.endpoint, companyId)
            let cookies = new Cookies()
            cookies.set('company', company.getData(), { path: '/' });
            this.setState({ isLoading: false, company, signUpConfig })
            let queryValues = queryString.parse(this.props.location.search)
            if(queryValues && queryValues.primaryProperty) {
                let primaryPropertyValue = queryValues.primaryProperty as string
                if(signUpConfig.primaryProperty == 'doc') this.setState({ doc: primaryPropertyValue })
                else this.setState({ email: primaryPropertyValue })
            }
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    updateDoc = (docConfig: DocConfig, newValue: string) => {
        if (docConfig.mask) {
            if (docConfig.mask == 'cpf') {
                let cpfWithMask = this.applyCpfMask(newValue)
                return this.setState({ doc: cpfWithMask })
            }
        }

        this.setState({ doc: newValue })
    }

    updateEmail = (newValue: string) => {
        this.setState({ email: newValue })
    }

    updatePassword = (password: string) => {
        this.setState({ password })
    }

    applyCpfMask = (value: string) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    isEmailValid = (email: string): boolean => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    testCPF = (strCPF: string) => {
        strCPF = strCPF.replace(/\D/g, '')
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (let n = 1; n <= 10; n++) Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (12 - n);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    isInputInvalid = (signUpConfig: SignUpConfig) => {
        if (signUpConfig.doc && signUpConfig.doc.required) {
            let docConfig = signUpConfig.doc
            if (docConfig.mask) {
                if (docConfig.mask == 'cpf') {
                    if(!this.testCPF(this.state.doc || '')) return true
                }
            }

            if(!this.state.doc || this.state.doc.length < 4) return true

        }

        if(signUpConfig.email) {
            if(signUpConfig.email.required) {
                if(!this.state.email || !this.isEmailValid(this.state.email)) return true
            } else {
                if(this.state.email && this.state.email.length > 0 && !this.isEmailValid(this.state.email)) return true
            }
        }

        if(signUpConfig.password) {
            if(!this.state.password || this.state.password.length < 8) return true
        }
        
        return false
        
    }

    signIn = async (companyId: string, signUpConfig: SignUpConfig) => {
        try {
            let signInData: any = { companyId }
            if(signUpConfig.primaryProperty == 'doc') signInData.doc = this.state.doc
            else signInData.email = this.state.email
            if(signUpConfig.password) signInData.password = this.state.password
            this.setState({ isLoading: true })
            let enterService = new EnterService()
            let userRes = await enterService.signInUser(config.endpoint, companyId, signInData)
            let cookies = new Cookies()
            cookies.set('user', userRes.user.getData(), { path: '/' })
            cookies.set('token', userRes.tokenData.token, { path: '/' })
            cookies.set('refreshToken', userRes.tokenData.refreshToken, { path: '/' })
            this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.MAIN}`)
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    renderDocInput(docConfig: DocConfig) {
        return (<FormGroup>
            <Label for="docTitle">{docConfig.title}</Label>
            <Input name="title" id="docTitle" placeholder={docConfig.placeholder} value={this.state.doc || ''} onChange={(event: any) => this.updateDoc(docConfig, event.target.value)} />
        </FormGroup>)
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderEmailInput(emailConfig: EmailConfig) {
        return (<FormGroup>
            <Label for="docTitle">{emailConfig.title}</Label>
            <Input name="title" id="docTitle" placeholder={emailConfig.placeholder} value={this.state.email} onChange={(event: any) => this.updateEmail(event.target.value)} />
        </FormGroup>)
    }

    renderPasswordInput() {
        return (<FormGroup>
            <Label for="passwordTitle">Senha</Label>
            <Input type="password" name="title" id="passwordTitle" placeholder={'Por favor, digite uma senha'} value={this.state.password} onChange={(event: any) => this.updatePassword(event.target.value)} />
        </FormGroup>)
    }

    renderSignInForm(config: SignUpConfig, company: Company) {
        return (<Container className="d-flex flex-column justify-content-center" fluid>
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ minHeight: '20vh', maxHeight: '20vh', marginBottom: 5 }} src={company.pic} />
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                    <Form>
                        {config.primaryProperty == 'doc' && this.renderDocInput(config.doc!)}
                        {config.primaryProperty == 'email' && this.renderEmailInput(config.email!)}
                        {config.password && this.renderPasswordInput()}
                    </Form>
                    <Button style={{ background: company.mainColor || '#000' }} block disabled={this.isInputInvalid(config)} onClick={() => this.signIn(company.id, config)}>Entrar</Button>
                </Col>
            </Row>
        </Container>)
    }

    render() {

        const { signUpConfig, company, error, isLoading } = this.state;

        if(isLoading || !company) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        return (<div>
            {error && this.renderError(error)}
            {signUpConfig && company && this.renderSignInForm(signUpConfig, company)}
        </div>)
    }
}

const LoginScreen = compose<Props, {}>(withRouter)(LoginScreenBase)

export default LoginScreen;
import React, { Component, FunctionComponent } from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';

// Landing
import LandingScreen from './screens/landing'
import ProductsScreen from './screens/landing/products'
import ContactScreen from './screens/landing/contact'
import TestScreen from './screens/landing/test/test_v2'
import TestSuModuleScreen from './screens/landing/test/selectSubModule'
import TestResultScreen from './screens/landing/test/gameResult'
import LeadershipScreen from './screens/landing/leadership'
import TimeAdminScreen from './screens/landing/timeadmin'
import TeamWorkScene from './screens/landing/teamwork'

// App
import LoadingScreen from './screens/loading'
import EnterScreen from './screens/enter'
import SignUpScreen from './screens/signup'
import LoginScreen from './screens/login'
import MainScreen from './screens/main'
import SubModuleSelectionScreen from './screens/main/SubModuleSelectionScreen'
import GameScreen from './screens/game'
import GameResultScreen from './screens/game/GameResultScreen'
import ModuleRankingScreen from './screens/ranking/ModuleRankingScreen'
import CompanyRankingScreen from './screens/ranking/CompanyRankingScreen'
import SelectCompanyScreen from './screens/main/SelectCompanyScreen'
import InsertNewPasswordScreen from './screens/recoverpwd/InsertNewPasswordScreen'

//dev
import DevScreen from './screens/dev'

import * as ROUTES from './constants/routes'

class App extends Component {

  render() {
    return (
      <Router>
        <Route exact path={ROUTES.LANDING}  component={LandingScreen} />
        <Route path={ROUTES.PRODUCTS}  component={ProductsScreen} />
        <Route path={ROUTES.CONTACT}  component={ContactScreen} />
        <Route exact path={ROUTES.TEST}  component={TestScreen} />
        <Route path={`${ROUTES.TEST}${ROUTES.SUBMODULE_SELECT}`} component={TestSuModuleScreen} />
        <Route path={`${ROUTES.TEST}${ROUTES.RESULT}`} component={TestResultScreen} />
        <Route path={ROUTES.LEADERSHIP}  component={LeadershipScreen} />
        <Route path={ROUTES.TIME_ADMIN}  component={TimeAdminScreen} />
        <Route path={ROUTES.TEAM_WORK}  component={TeamWorkScene} />
        <Route path={ROUTES.APP_LANDING} component={AppRoutes} />
        <Route path='/dev' component={DevScreen} />
      </Router>
    )
  }
}

interface Props extends RouteComponentProps {}

class AppRoutes extends Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <Router>
        <Route exact path={`${this.props.match.path}`}  component={SelectCompanyScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.LOADING}`} component={LoadingScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.ENTER}/:companyId`} component={EnterScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.SIGN_UP}`} component={SignUpScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.LOGIN}`} component={LoginScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.MAIN}`} component={MainScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.SUBMODULE_SELECT}`} component={SubModuleSelectionScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.GAME}`} component={GameScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.RESULT}`} component={GameResultScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.MODULE_RANKING}`} component={ModuleRankingScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.COMPANY_RANKING}`} component={CompanyRankingScreen} />
        <Route exact path={`${this.props.match.path}${ROUTES.NEW_PWD}/:token`} component={InsertNewPasswordScreen} />
      </Router>
    )
  }
}

export default App;

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import User from '../../models/User';
import TokenHelper from '../../auth/TokenHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUserCircle, faInfo } from '@fortawesome/free-solid-svg-icons';

import RankingService from '../../services/rankingService';
import { RankingEntry } from '../../models/Ranking';
import Module from '../../models/Module';
import ContentService from '../../services/contentService';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company?: Company;
    user?: User;
    moduleId?: string;
    module?: Module;
    rankingEntries?: Array<RankingEntry>
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    moduleId: undefined,
    module: undefined,
    rankingEntries: undefined
};

interface Props extends RouteComponentProps { }

class SubModuleRankingBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData && userData) {
            let company = new Company(companyData)
            let user = new User(userData)
            let queryValues = queryString.parse(this.props.location.search)
            if (queryValues && queryValues.moduleId) {
                let moduleId = queryValues.moduleId as string
                this.setState({ company, user, moduleId }, () => this.loadModule(company.id, moduleId))
            } else {
                this.setState({ error: 'Missing moduleId in query.' })
            }
        } else {
            this.setState({ error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }

    loadModule = async (companyId: string, moduleId: string) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let module = await contentService.getCompanyModule(token, config.endpoint, companyId, moduleId)
            this.setState({ module, isLoading: false }, () => this.loadRanking(companyId, moduleId))
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadModule(companyId, moduleId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    loadRanking = async (companyId: string, moduleId: string) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let rankingService = new RankingService()
            let rankingEntries = await rankingService.getModuleRanking(token, config.endpoint, companyId, moduleId)
            this.setState({ rankingEntries, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadRanking(companyId, moduleId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    onExit = () => {
        this.props.history.goBack()
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderModuleHeader(module: Module) {
        return (<Container className="d-flex flex-column justify-content-center" fluid>
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ width: 100, height: 100, margin: 10, borderRadius: 10 }} src={module.pic} />
                    </div>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 20 }}>
                        {module.title}
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 5 }}>
                        <div><FontAwesomeIcon color='black' icon={faInfo} /></div>
                        <div style={{ color: 'black', fontFamily: 'Montserrat', textAlign: 'start', marginLeft: 10, fontSize: 12 }}>Sua pontuação na missão é a soma da maior pontuação em cada nível</div>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    renderRanking(rankingEntries: Array<RankingEntry>) {
        return (<Container className="d-flex flex-column justify-content-center" fluid>
            {rankingEntries.map(rankingEntry => this.renderRankingEntry(rankingEntry))}
        </Container>)
    }

    renderRankingEntry(rankingEntry: RankingEntry) {
        return (<Row key={rankingEntry.user.id}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                    <div><FontAwesomeIcon color='#1d3256' icon={faUserCircle} size='2x' /></div>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', flex: 1, marginLeft: 5 }}>{rankingEntry.user.username}</div>
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'start', marginRight: 5, marginLeft: 5 }}>{rankingEntry.score}</div>
                </div>
            </Col>
        </Row>)
    }

    render() {

        let { company, error, isLoading, module, rankingEntries } = this.state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        return (<div>
            {error && this.renderError(error)}
            {module && this.renderModuleHeader(module)}
            {rankingEntries && this.renderRanking(rankingEntries)}
        </div>)
    }

}

const SubModuleRankingScreen = compose<Props, {}>(withRouter)(SubModuleRankingBase)

export default SubModuleRankingScreen
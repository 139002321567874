import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, Button } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import Swipe from '../game/swipe';
import DeckCard, { QuestionCard } from '../../models/DeckCards';

interface State {
};

const INITIAL_STATE: State = {
};

interface Props extends RouteComponentProps {  }

class QuestionWithFeedbackCard extends QuestionCard {

    defaultImage: string
    correctImage: string
    wrongImage: string

    constructor(data: any) {
        super(data)
        this.defaultImage = data.defaultImage
        this.correctImage = data.correctImage
        this.wrongImage = data.wrongImage
    }
}

class DevScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    renderCard(card: DeckCard) {
        return (<div>
            <canvas ref="canvas" width={640} height={425} />
        </div>)
    }


    render() {
        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
        </Container>)
    }

}

const DevScreen = compose<Props, {}>(withRouter)(DevScreenBase)

export default DevScreen;

interface NewCardProps {  }

class NewCard extends Component<Props, State> {}
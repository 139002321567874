import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'
import * as _ from 'lodash'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faGamepad, faListOl, faTrophy } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes'
import User from '../../models/User';
import ContentService from '../../services/contentService';
import TokenHelper from '../../auth/TokenHelper';
import Module from '../../models/Module';
import SubModule, { SubModuleTypes } from '../../models/SubModule'
import { UserMaxScoreInSubModule } from '../../models/Scores';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    user: User | undefined;
    module: Module | undefined;
    subModules: Array<SubModule>;
    moduleId: string | undefined;
    userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    module: undefined,
    subModules: [],
    moduleId: undefined,
    userMaxScoreInSubModules: []
};

interface Props extends RouteComponentProps { }

class SubModuleSelectionBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData && userData) {
            let company = new Company(companyData)
            let user = new User(userData)
            let queryValues = queryString.parse(this.props.location.search)
            if (queryValues && queryValues.moduleId) {
                let moduleId = queryValues.moduleId as string
                this.setState({ company, user, moduleId }, () => this.loadModuleAndSubModules(company.id, moduleId))
            } else {
                this.setState({ error: 'Missing moduleId in query.' })
            }
        } else {
            this.setState({ error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }

    async loadModuleAndSubModules(companyId: string, moduleId: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            let { module, subModules, userMaxScoreInSubModules } = await contentService.getCompanyModuleAndSubmodules(token, config.endpoint, companyId, moduleId)
            this.setState({ module, subModules, userMaxScoreInSubModules, isLoading: false })
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadModuleAndSubModules(companyId, moduleId)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    goToModuleRanking = (moduleId: string) => {
        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.MODULE_RANKING}?moduleId=${moduleId}`)
    }

    playSubModule = (subModule: SubModule) => {
        this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.GAME}?subModuleId=${subModule.id}&subModuleType=${subModule.type}`)
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderSubModules(company: Company, subModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>) {
        let sortedSubModules = subModules.sort((a, b) => { return a.pos - b.pos })
        let shouldShowQuiz = userMaxScoreInSubModules.length >= sortedSubModules.filter(subModule => subModule.type == SubModuleTypes.DECK).length
        return sortedSubModules.map(subModule => {
            let userMaxScoreInSubModule = _.head(userMaxScoreInSubModules.filter(maxScore => maxScore.subModuleId == subModule.id))
            return (<div key={subModule.id} className="d-flex flex-column" style={{ marginTop: 10, marginBottom: 5, borderRadius: 2, boxShadow: '2px 4px 8px 2px rgba(0,0,0,0.2)' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 2 }}>
                    <img style={{ height: 50, width: 50, minWidth: 50, borderRadius: 5, margin: 5 }} src={subModule.pic} />
                    <div style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' }}>{subModule.title}</div>
                </div>
                {userMaxScoreInSubModule && (<div className="d-flex justify-content-center align-items-center" style={{ marginTop: 2 }}>
                    <div><FontAwesomeIcon color='#5c959e' icon={faTrophy} /></div>
                    <div style={{ marginLeft: 5, color: '#5c959e', fontFamily: 'Montserrat', textAlign: 'start' }}>{`Maior pontuação: ${userMaxScoreInSubModule.score}`}</div>
                </div>)}
                {this.renderPlayButton(subModule, shouldShowQuiz, company.mainColor)}
            </div>)
        })
    }

    renderPlayButton(subModule: SubModule, shouldShowQuiz: boolean, companyMainColor: string | undefined) {
        let isQuizSubModule = subModule.type == SubModuleTypes.QUIZ
        if (isQuizSubModule && !shouldShowQuiz) {
            return (<div className="d-flex justify-content-center align-items-center">
                <Button disabled={true} style={{ background: companyMainColor || '#000', margin: 10 }} block ><FontAwesomeIcon icon={faGamepad} /> Finalize os níveis para jogar</Button>
            </div>)
        } else {
            return (<div className="d-flex justify-content-center align-items-center">
                <Button style={{ background: companyMainColor || '#000', margin: 10 }} block onClick={() => { this.playSubModule(subModule) }}><FontAwesomeIcon icon={faGamepad} /> Jogar</Button>
            </div>)
        }
    }

    renderHeader(module: Module) {
        return (
            <Row>
                <Col className="d-flex flex-column justify-content-center relative" md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center">
                        <img style={{ width: 100, height: 100, margin: 10, borderRadius: 10 }} src={module.pic} />
                    </div>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', fontSize: 20 }}>
                        {module.title}
                    </div>
                    <div style={{ position: 'absolute', alignSelf: 'center', top: 10, left: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                </Col>
            </Row>)
    }

    renderSubModuleSelection(company: Company, subModules: Array<SubModule>, userMaxScoreInSubModules: Array<UserMaxScoreInSubModule>) {
        return (
            <Row style={{ flex: 1, overflow: 'auto' }}>
                <Col className="d-flex flex-column" md={{ size: 6, offset: 3 }}>
                    {this.renderSubModules(company, subModules, userMaxScoreInSubModules)}
                </Col>
            </Row>)
    }

    renderBottomNavBar(module: Module) {

        return (<Row style={{ marginTop: 5, marginBottom: 5 }}>
            <Col md={{ size: 6, offset: 3 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
                    <Button style={{ padding: 5, width: '100%', background: '#343a40' }} onClick={() => { this.goToModuleRanking(module.id) }}><FontAwesomeIcon icon={faListOl} /> Ver Ranking desta Missão</Button>
                </div>
            </Col>
        </Row>)
    }

    render() {

        let { company, error, isLoading, module, subModules, userMaxScoreInSubModules } = this.state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }

        return (<Container className="d-flex flex-column" style={{ overflow: 'hidden', height: '100vh' }}>
            {error && this.renderError(error)}
            {module && this.renderHeader(module)}
            {subModules && this.renderSubModuleSelection(company!, subModules, userMaxScoreInSubModules)}
            {module && this.renderBottomNavBar(module)}
        </Container>)

    }

}

const SubModuleSelectionScreen = compose<Props, {}>(withRouter)(SubModuleSelectionBase)

export default SubModuleSelectionScreen
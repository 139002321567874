export default class User {
    id: string
    username: string
    companyId: string
    doc?: string
    email?: string
    pic?: string
    thumbnail?: string
    linkedInId?: string
    

    constructor(data: any) {
        this.id = data.id
        this.username = data.username
        this.doc = data.doc
        this.email = data.email
        this.pic = data.pic
        this.thumbnail = data.thumbnail
        this.linkedInId = data.linkedInId
        this.companyId = data.companyId
    }   

    getData(): any {
        let data: any = {
            id: this.id,
            username: this.username,
            companyId: this.companyId
        }
        if(this.doc) data.doc = this.doc
        if(this.email) data.email = this.email
        if(this.linkedInId) data.linkedInId = this.linkedInId
        if(this.pic) data.pic = this.pic
        if(this.thumbnail) data.thumbnail = this.thumbnail
        return data
    }
}
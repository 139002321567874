import React, { Component, FunctionComponent, CSSProperties } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHome, faGamepad, faPhone, faTimes, faChevronCircleLeft, faChevronCircleRight,
    IconDefinition, faTimesCircle, faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { Spring } from 'react-spring/renderprops'


import * as ROUTES from '../../constants/routes'
import { portariaData as data } from './data'
import SwipeDemo from './swipeDemo'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum QuestionAnswer {
    NotAnswered = 'NotAnswered',
    Timeout = 'Timeout',
    CorrectAnswer = 'CorrectAnswer',
    WrongAnswer = 'WrongAnswer'
}

class Question {
    pos: number
    answer: QuestionAnswer
    selectedAnswer: number
    constructor(pos: number, answer: QuestionAnswer, selectedAnswer: number = -1) {
        this.pos = pos
        this.answer = answer
        this.selectedAnswer = selectedAnswer
    }
}

interface State {
    isNavBarMenuOpen: boolean,
    currentIndex: number,
    questions: Array<Question>
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentIndex: 0,
    questions: data.filter(d => d.type == 'question').map(question => { return new Question(question.pos, QuestionAnswer.NotAnswered) })
}

interface Props extends RouteComponentProps { }

class LeadershipScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = { ...INITIAL_STATE }
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    getCardQuestion(card: any) {
        let filter = this.state.questions.filter(question => { return question.pos == card.pos })
        return filter[0]
    }

    onAnswerSelected = (answerNumber: number) => {
        let questions = this.state.questions
        let currentCard = data[this.state.currentIndex]
        let currentQuestionFilter = questions.filter(question => { return question.pos == currentCard.pos })
        if (currentQuestionFilter.length > 0) {
            let currentQuestion = currentQuestionFilter[0]
            if (currentQuestion.answer != QuestionAnswer.NotAnswered) return
            currentQuestion.selectedAnswer = answerNumber
            if (answerNumber == currentCard.correctAnswer) {
                currentQuestion.answer = QuestionAnswer.CorrectAnswer
            } else {
                currentQuestion.answer = QuestionAnswer.WrongAnswer
            }
            this.setState({ questions: [...this.state.questions] })
        }
    }

    animatedIcon(icon: IconProp, size: string) {
        let random = Math.random()
        if(random > 0.75) {
            return (<Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>
                    <FontAwesomeIcon color='#5c959e' icon={icon} size={size == 'small' ? '2x' : '4x'} />
                </div>}
            </Spring>)
        } else if(random > 0.5) {
            return (<Spring
                from={{ marginLeft: -1000 }}
                to={{ marginLeft: 0 }}>
                {props => <div style={props}>
                    <FontAwesomeIcon color='#1d3256' icon={icon} size={size == 'small' ? '2x' : '4x'} />
                </div>}
            </Spring>)
        } else if(random > 0.25) {
            return (<Spring
                from={{ marginRight: -1000 }}
                to={{ marginRight: 0 }}>
                {props => <div style={props}>
                    <FontAwesomeIcon color='#1d3256' icon={icon} size={size == 'small' ? '2x' : '4x'} />
                </div>}
            </Spring>)
        } else {
            return (<Spring
                from={{ marginTop: -1000 }}
                to={{ marginTop: 0 }}>
                {props => <div style={props}>
                    <FontAwesomeIcon icon={icon} size={size == 'small' ? '2x' : '4x'} />
                </div>}
            </Spring>)
        }
    }

    renderCard = (card: any) => {
        let currentCard = card.pos == this.state.currentIndex
        if (card.type == 'first') {
            return (<Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>
                    <FontAwesomeIcon icon={card.image} size='4x' />
                    <h4 style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10 }}>{card.text}</h4>
                </div>}
            </Spring>)
        } else if (card.type == 'description') {
            return (<div>
                <FontAwesomeIcon icon={card.image} size='2x' />
                <p style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 5 }}>{card.title}</p>
                {card.texts.map((text: string) => { return (<p>{text}</p>) })}
            </div>)
        } else if (card.type == 'info') {
            return (<div>
                {currentCard && this.animatedIcon(card.image, card.imageSize)}
                <p style={{ color: '#1d3256', fontFamily: 'Montserrat', marginTop: 10, fontSize: card.fontSize == 'normal' ? 22 : 28 }}>{card.text}</p>
            </div>)
        } else if (card.type == 'question') {
            return this.renderQuestionCard(card)
        } else {
            return (<div>
                <p>Desconhecido</p>
            </div>)
        }
    }

    renderQuestionCard(card: any) {
        let question = this.getCardQuestion(card)
        let correctAnswer = card.correctAnswer
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 1, textAlign: 'center', color: 'black', fontFamily: 'Montserrat' }}>{card.question}</div>
                    <AnswerComponent answerText={card.answer0} selectedAnswer={question.selectedAnswer} answerNumber={0}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />
                    <AnswerComponent answerText={card.answer1} selectedAnswer={question.selectedAnswer} answerNumber={1}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />
                    {card.answer2 && <AnswerComponent answerText={card.answer2} selectedAnswer={question.selectedAnswer} answerNumber={2}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
                    {card.answer3 && <AnswerComponent answerText={card.answer3} selectedAnswer={question.selectedAnswer} answerNumber={3}
                        questionAnswer={question.answer} onAnswerSelected={this.onAnswerSelected} correctAnswer={correctAnswer} />}
                </div>
            </Col>
        </Row>)
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href={ROUTES.LANDING}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.PRODUCTS}><FontAwesomeIcon icon={faGamepad} /> Produto</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.CONTACT}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    renderMainContent() {
        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e', paddingTop: 55 }}>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        <Button color='none' outline onClick={() => { this.props.history.push(ROUTES.TEST) }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button>
                    </div>
                    <div style={{ flex: 1, marginLeft: 5 }}>
                        <div className="text-center" style={{ color: '#1d3256', fontFamily: 'Montserrat' }}>{`${this.state.currentIndex + 1} de ${data.length}`}</div>
                        <Progress color='dark' value={this.state.currentIndex + 1} max={data.length} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ height: '75vh' }} md={{ size: 6, offset: 3 }}>
                    <SwipeDemo cards={data} renderCard={this.renderCard} currentIndex={this.state.currentIndex} />
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    {this.renderNavigationButtons()}
                </Col>
            </Row>
        </Container>)
    }

    renderNavigationButtons() {
        return (
            <div style={{ display: 'flex', padding: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                {<Button disabled={this.state.currentIndex == 0} color="secondary" onClick={() => { let currentIndex = this.state.currentIndex; this.setState({ currentIndex: currentIndex - 1 }) }}><FontAwesomeIcon icon={faChevronCircleLeft} /> Anterior</Button>}
                {<Button disabled={this.state.currentIndex >= data.length - 1} color="secondary" onClick={() => { let currentIndex = this.state.currentIndex; this.setState({ currentIndex: currentIndex + 1 }) }}>Próximo <FontAwesomeIcon icon={faChevronCircleRight} /></Button>}
            </div>
        )
    }

    render() {
        return (<div>
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }
}

const LeadershipScreen = compose<Props, {}>(withRouter)(LeadershipScreenBase);
export default LeadershipScreen

interface AnswerComponentProps {
    answerText: string,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer,
    correctAnswer: number,
    onAnswerSelected: (answerNumber: number) => void
}

export class AnswerComponent extends Component<AnswerComponentProps> {

    constructor(props: AnswerComponentProps) {
        super(props)
    }

    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined) {
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={style}>
                <strong>{this.props.answerText}</strong> {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if (icon) return (<FontAwesomeIcon icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer == this.props.answerNumber) {
            if (this.props.questionAnswer == QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
            }
        } else if (this.props.questionAnswer == QuestionAnswer.WrongAnswer && this.props.answerNumber == this.props.correctAnswer) {
            return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
        } else {
            return this.renderDiv(questionDefaultStyle)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    flex: 1, textAlign: 'center',
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2,
    paddingLeft: 2, paddingRight: 2, color: 'black', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
}

const questionWrongAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2,
    paddingLeft: 2, paddingRight: 2, color: 'white', background: 'red', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
}

const questionCorrectAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1,
    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, paddingLeft: 2, paddingRight: 2,
    color: 'white', background: 'green', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
}


import React, { Component, FunctionComponent, CSSProperties } from 'react';
import { compose } from 'recompose'
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Container, Row, Col, Spinner, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGamepad, faPhone, faCheckCircle, faTimesCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons'

import * as ROUTES from '../../constants/routes'
import { timeManagementData as data } from './data'

const answerTime = 45

interface State {
    isNavBarMenuOpen: boolean,
    currentIndex: number,
    questionsState: Array<QuestionState>,
    seconds: number,
    isTimerOn: boolean,
    time: number,
    start: number,
    selectedAnswer: number
}

const INITIAL_STATE: State = {
    isNavBarMenuOpen: false,
    currentIndex: 0,
    questionsState: [],
    seconds: 0,
    isTimerOn: false,
    time: 0,
    start: 0,
    selectedAnswer: -1
};

interface Props extends RouteComponentProps { }

class TimeAdminScreenBase extends Component<Props, State> {

    timer: any

    constructor(props: Props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        INITIAL_STATE.questionsState = data.map(data => new QuestionState(data, QuestionAnswer.NotAnswered))
        this.state = { ...INITIAL_STATE }

        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
    }

    componentDidMount() {
        // Init timer
        if (!this.state.isTimerOn) {
            this.startTimer()
        }
    }

    startTimer() {
        this.setState({
            isTimerOn: true,
            time: this.state.time,
            start: Date.now()
        })
        this.timer = setInterval(() => { this.timerTick() }, 1);
    }

    timerTick() {
        let time = Math.round((Date.now() - this.state.start) / 1000)
        if (time <= answerTime) {
            this.setState({
                time: Math.round((Date.now() - this.state.start) / 1000)
            })
        } else {
            this.stopTimer()
            if (this.state.currentIndex < data.length - 1) {
                this.setState({ currentIndex: this.state.currentIndex + 1 })
                this.resetTimer()
                this.startTimer()
            }
        }
    }

    stopTimer() {
        this.setState({ isTimerOn: false })
        clearInterval(this.timer)
    }

    resetTimer() {
        this.setState({ time: 0, isTimerOn: false })
    }

    toggle() {
        this.setState({
            isNavBarMenuOpen: !this.state.isNavBarMenuOpen
        });
    }

    onAnswerSelected = (answerNumber: number) => {
        let currentQuestion = this.state.questionsState[this.state.currentIndex]
        if(currentQuestion.questionAnswer == QuestionAnswer.NotAnswered) {
            currentQuestion.questionAnswer = answerNumber == currentQuestion.questionCardData.correctAnswer ? QuestionAnswer.CorrectAnswer : QuestionAnswer.WrongAnswer
            this.setState({ questionsState: [...this.state.questionsState], selectedAnswer: answerNumber })
            this.stopTimer()
            if(this.state.currentIndex < this.state.questionsState.length - 1) {
                setTimeout(() => {
                    this.setState({ currentIndex: this.state.currentIndex + 1, selectedAnswer: -1 })
                    this.resetTimer()
                    this.startTimer()
                }, 2000)
            }
        }
    }

    renderNavBar() {
        return (<Navbar color="light" light expand="md" fixed="top">
            <NavbarBrand href={ROUTES.LANDING}>
                <img
                    alt=""
                    src={require('../../images/gmfyit_no_background_192.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isNavBarMenuOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href={ROUTES.LANDING}><FontAwesomeIcon icon={faHome} /> Início</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.PRODUCTS}><FontAwesomeIcon icon={faGamepad} /> Produto</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={ROUTES.CONTACT}><FontAwesomeIcon icon={faPhone} /> Atendimento</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }

    drawTimer() {
        return (<Row>
            <Col style={{ marginTop: 5 }} md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', height: 52, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <div style={{ flex: 1 }}> <Progress value={answerTime - this.state.time} max={answerTime} /> </div>
                    <div style={{ position: 'absolute', background: '#1d3256', height: 50, width: 50, borderRadius: 25 }} />
                    <div style={{ position: 'absolute', color: 'white', fontSize: 18, padding: 5, textAlign: 'center', verticalAlign: 'middle' }}>
                        {answerTime - this.state.time}
                    </div>
                </div>
            </Col>
        </Row>)
    }

    drawCurrentQuestion(questionState: QuestionState) {
        return (<Row style={{ flex: 1 }}>
            <Col style={{ marginTop: 5 }} md={{ size: 6, offset: 3 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', height: '100%' }}>
                    <div style={{ margin: 5, textAlign: 'center', color: 'black', fontFamily: 'Montserrat' }}>{questionState.questionCardData.question}</div>
                    <AnswerComponent answerText={questionState.questionCardData.answer0} selectedAnswer={this.state.selectedAnswer} answerNumber={0}
                        questionAnswer={questionState.questionAnswer} onAnswerSelected={this.onAnswerSelected} />
                    <AnswerComponent answerText={questionState.questionCardData.answer1} selectedAnswer={this.state.selectedAnswer} answerNumber={1}
                        questionAnswer={questionState.questionAnswer} onAnswerSelected={this.onAnswerSelected} />
                    <AnswerComponent answerText={questionState.questionCardData.answer2} selectedAnswer={this.state.selectedAnswer} answerNumber={2}
                        questionAnswer={questionState.questionAnswer} onAnswerSelected={this.onAnswerSelected} />
                    <AnswerComponent answerText={questionState.questionCardData.answer3} selectedAnswer={this.state.selectedAnswer} answerNumber={3}
                        questionAnswer={questionState.questionAnswer} onAnswerSelected={this.onAnswerSelected} />
                </div>
            </Col>
        </Row>)
    }

    renderMainContent() {
        let currentQuestion = this.state.questionsState[this.state.currentIndex]
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: 'white', paddingTop: 55 }}>
            {this.drawTimer()}
            {this.drawCurrentQuestion(currentQuestion)}
        </Container>)
    }

    render() {
        return (<div>
            {this.renderNavBar()}
            {this.renderMainContent()}
        </div>)
    }

}

const TimeAdminScreen = compose<Props, {}>(withRouter)(TimeAdminScreenBase);
export default TimeAdminScreen

class QuestionState {
    questionCardData: any
    questionAnswer: QuestionAnswer

    constructor(questionCardData: any, questionAnswer: QuestionAnswer) {
        this.questionCardData = questionCardData
        this.questionAnswer = questionAnswer
    }
}

export enum QuestionAnswer {
    NotAnswered = 'NotAnswered',
    Timeout = 'Timeout',
    CorrectAnswer = 'CorrectAnswer',
    WrongAnswer = 'WrongAnswer'
}

interface AnswerComponentProps {
    answerText: string,
    selectedAnswer: number,
    answerNumber: number,
    questionAnswer: QuestionAnswer
    onAnswerSelected: (answerNumber: number) => void
}

export class AnswerComponent extends Component<AnswerComponentProps> {

    constructor(props: AnswerComponentProps) {
        super(props)
    }
  
    renderDiv(style: CSSProperties, icon: IconDefinition | undefined = undefined) {
        return (
            <div onClick={() => { this.props.onAnswerSelected(this.props.answerNumber) }} style={style}>
                <strong>{this.props.answerText}</strong> {this.renderIcon(icon)}
            </div>
        )
    }

    renderIcon(icon: IconDefinition | undefined = undefined) {
        if(icon) return (<FontAwesomeIcon icon={icon} size='2x' />)
    }

    render() {
        if (this.props.selectedAnswer == this.props.answerNumber) {
            if (this.props.questionAnswer == QuestionAnswer.WrongAnswer) {
                return this.renderDiv(questionWrongAnswerStyle, faTimesCircle)
            } else {
                return this.renderDiv(questionCorrectAnswerStyle, faCheckCircle)
            }
        } else {
            return this.renderDiv(questionDefaultStyle)
        }
    }
}

const questionDefaultStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: '#1d3256', borderStyle: 'solid', borderWidth: 1,
    borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5,
    padding: 5, color: '#1d3256'
}

const questionWrongAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'red', borderStyle: 'solid', borderWidth: 1,
    borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5,
    padding: 5, color: 'white', background: 'red'
}

const questionCorrectAnswerStyle: CSSProperties = {
    flex: 1, textAlign: 'center', borderColor: 'green', borderStyle: 'solid', borderWidth: 1,
    borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5,
    padding: 5, color: 'white', background: 'green'
}
import React, { Component, FunctionComponent, CSSProperties } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { render } from 'react-dom'
import WordSearchManager from './WordSearchManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface State {
    selection: Array<string>
    wordPositioning: Array<Array<boolean>>
    randomLetters: Array<string>
}

interface Props { rows: number, columns: number, word: string, title: string, onWordFound: () => void, onExit: () => void }

export default class WordSearchComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        let currentWord = props.word.toUpperCase()
        let wordSearchManager = new WordSearchManager(this.props.rows, this.props.columns)
        let wordPositioning = wordSearchManager.initWord(currentWord)
        let allLetters = 'AÃÁBCDEÊÉFGHIÍJKLMNOÓÕPQRSTUVWXYZ'
        let randomLetters: Array<string> = []
        let randomLettersLength = (this.props.rows * this.props.columns) - currentWord.length
        for (let i = 0; i < randomLettersLength; i++) {
            randomLetters.push(allLetters[Math.round(Math.random() * (allLetters.length - 1))])
        }
        this.state = { selection: [], wordPositioning, randomLetters }
    }

    onLetterSelected(column: number, row: number) {
        if (this.isLetterSelected(column, row)) {
            let newSelection = this.state.selection.filter(data => data != `${column},${row}`)
            this.setState({ selection: newSelection }, () => {
                if (this.isWordFound()) this.props.onWordFound()
            })
        } else {
            let newSelection = [...this.state.selection]
            newSelection.push(`${column},${row}`)
            this.setState({ selection: newSelection }, () => {
                if (this.isWordFound()) this.props.onWordFound()
            })
        }
    }

    isLetterSelected(column: number, row: number) {
        return this.state.selection.filter(data => data == `${column},${row}`).length > 0
    }

    getStyleForLetter(column: number, row: number): CSSProperties {
        if (this.isLetterSelected(column, row)) {
            return letterSelectedStyle
        } else {
            return letterDefaultStyle
        }
    }

    isWordFound() {
        for (let col = 0; col < this.props.columns; col++) {
            let currentCol = this.state.wordPositioning[col]
            for (let row = 0; row < this.props.rows; row++) {
                let currentRow = currentCol[row]
                if (this.isLetterSelected(col, row)) {
                    if (!currentRow) return false
                } else {
                    if (currentRow) return false
                }
            }
        }

        return true
    }

    render() {
        let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        let currentWord = this.props.word.toUpperCase()
        let currentWordIndex = -1
        let randomLetterIndex = -1
        return (<Container className="d-flex flex-column" fluid style={{ background: 'white', width: '100%' }}>
            <Row style={{ marginTop: 10 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 20, verticalAlign: 'middle', textAlign: 'center' }}>
                        {this.props.title}
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 2 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    {array.map(column => {
                        return (
                            <div key={`col-${column}`} className="d-flex">
                                {array.map(row => {
                                    if (this.state.wordPositioning[column][row]) {
                                        currentWordIndex++
                                        return (<div key={`row-${row}`} style={this.getStyleForLetter(column, row)} className="d-flex flex-column" onClick={() => this.onLetterSelected(column, row)}>
                                            {currentWord[currentWordIndex]}
                                        </div>)
                                    } else {
                                        randomLetterIndex++
                                        return (<div key={`row-${row}`} style={this.getStyleForLetter(column, row)} className="d-flex flex-column" onClick={() => this.onLetterSelected(column, row)}>
                                            {this.state.randomLetters[randomLetterIndex]}
                                        </div>)
                                    }
                                })}
                            </div>
                        )
                    })}
                </Col>
            </Row>
            <Row style={{ marginTop: 2 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 14 }}>
                        {`Dica: começa com a letra ${currentWord[0]}`}
                    </div>
                </Col>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 14 }}>
                        Encontre a palavra certa clicando nas letras
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 8 }}>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ color: '#1d3256', fontFamily: 'Montserrat', fontSize: 14 }}>
                        <Button color='none' outline onClick={() => { this.props.onExit() }}><FontAwesomeIcon color='#343a40' icon={faTimes} size='2x' /></Button><div style={{ color: '#343a40', fontSize: 18 }} onClick={() => { this.props.onExit() }}>Sair</div>
                    </div>
                </Col>
            </Row>
        </Container>)
    }


}

const letterDefaultStyle: CSSProperties = {
    color: '#1d3256', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderColor: '#1d3256', borderRadius: 2, borderStyle: 'solid',
    margin: 5, paddingTop: 2
}

const letterSelectedStyle: CSSProperties = {
    color: 'white', fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', flex: 1,
    borderWidth: 1, borderColor: 'black', borderRadius: 2, borderStyle: 'solid', background: 'green',
    margin: 5, paddingTop: 2
}
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Company from '../../models/Company'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import EnterService from '../../services/enterService'
import config from '../../config'
import LoadingScreen from '../loading';
import { Alert, Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from '../../constants/routes'

interface State {
    isLoading: boolean
    error: any | undefined
    companies: Array<Company> | undefined
};

const INITIAL_STATE: State = {
    isLoading: true,
    error: null,
    companies: undefined
};

interface Props extends RouteComponentProps { }

class SelectCompanyScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        this.loadCompanies()
    }

    loadCompanies = async () => {
        try {
            this.setState({ isLoading: true, error: undefined })
            let enterService = new EnterService()
            let companies = await enterService.getCompanies(config.endpoint)
            this.setState({ isLoading: false, companies })
        } catch (error) {
            this.setState({ isLoading: false, error: error.toString() })
        }
    }

    renderError(error: any) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderCompanies(companies: Array<Company>) {
        return (<Container fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ background: 'white', marginTop: 10, padding: 5, borderRadius: 5 }}>
                        <div className="d-flex flex-column justify-content-center" style={{ padding: 5, flex: 1 }}>
                            <div style={{ color: '#1d3256', fontFamily: 'Montserrat', textAlign: 'center' }}>Por favor, selecione uma empresa:</div>
                        </div>
                    </div>
                </Col>
            </Row>
            {companies.map(company => {
                return (<Row key={company.id}>
                    <Col className="d-flex flex-column justify-content-center" style={{ marginTop: 10, backgroundColor: 'white' }} md={{ size: 6, offset: 3 }}>
                        <div className="d-flex justify-content-center">
                            <img style={{ height: '10vh', marginBottom: 5, borderRadius: 5 }} src={company.pic} />
                        </div>
                        <div style={{ color: '#1d3256', fontFamily: 'Montserrat', fontStyle: 'bold', verticalAlign: 'middle', textAlign: 'center' }}>{company.name}</div>
                        <div className="d-flex justify-content-center align-items-center">
                            <Button style={{ background: company.mainColor || '#000', margin: 10 }} block onClick={() => { this.props.history.push(`${ROUTES.APP_LANDING}${ROUTES.ENTER}/${company.id}`) }}><FontAwesomeIcon icon={faCheck} /> Selecionar</Button>
                        </div>
                    </Col>
                </Row>)
            })}
        </Container>)
    }

    render() {
        let { error, isLoading, companies } = this.state
        if (isLoading) return <LoadingScreen />
        return (<div>
            {error && this.renderError(error)}
            {companies && this.renderCompanies(companies)}
        </div>)
    }

}

const SelectCompanyScreen = compose<Props, {}>(withRouter)(SelectCompanyScreenBase)

export default SelectCompanyScreen;

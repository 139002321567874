export default class Module {
    id: string
    companyId: string
    title: string
    pic: string
    description?: string 
    pos: number
    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.title = data.title
        this.pic = data.pic
        this.description = data.description
        this.pos = data.pos
    }
}
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Container, Row, Col, Button } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import queryString from 'query-string'

import LoadingScreen from '../loading'
import Cookies from 'universal-cookie';
import Company from '../../models/Company';
import config from '../../config'

import * as ROUTES from '../../constants/routes'
import User from '../../models/User';
import ContentService from '../../services/contentService'
import TokenHelper from '../../auth/TokenHelper';
import { SubModuleTypes } from '../../models/SubModule'
import DeckCard from '../../models/DeckCards';
import DeckCardsScreen from './DeckCardsScreen';
import QuizScreen from './QuizScreen'
import { DeckCardReport } from '../../models/DeckCardsReport';
import ScoreService from '../../services/scoreService';
import QuizQuestion from '../../models/QuizQuestion';
import { QuizQuestionsReport } from '../../models/QuizQuestionsReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

interface State {
    error: string | undefined;
    isLoading: boolean;
    company: Company | undefined;
    user: User | undefined;
    subModuleId: string | undefined
    subModuleType: string | undefined
    deckCards: Array<DeckCard> | undefined
    quizQuestions: Array<QuizQuestion> | undefined
    isFinished: boolean
    retry: (() => void) | undefined
};

const INITIAL_STATE: State = {
    error: undefined,
    isLoading: false,
    company: undefined,
    user: undefined,
    subModuleId: undefined,
    subModuleType: undefined,
    deckCards: undefined,
    quizQuestions: undefined,
    isFinished: false,
    retry: undefined
};

interface Props extends RouteComponentProps { }

class GameScreenBase extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        let cookies = new Cookies()
        let companyData = cookies.get('company')
        let userData = cookies.get('user')
        if (companyData && userData) {
            let company = new Company(companyData)
            let user = new User(userData)
            let queryValues = queryString.parse(this.props.location.search)
            if (queryValues && queryValues.subModuleId && queryValues.subModuleType) {
                let subModuleId = queryValues.subModuleId as string
                let subModuleType = queryValues.subModuleType as string
                this.setState({ company, user, subModuleId, subModuleType }, () => this.loadGameData(subModuleId, subModuleType))
            } else {
                this.setState({ error: 'Missing moduleId in query.' })
            }
        } else {
            this.setState({ error: 'Ops, link invalido. Você precisa fazer login primeiro.' })
        }
    }

    onExit = () => {
        this.props.history.goBack()
    }

    onFinishDeck = async (startTime: number, endTime: number, deckCardsReport: Array<DeckCardReport<DeckCard>>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserDeckScore(token, config.endpoint, startTime, endTime, deckCardsReport, this.state.subModuleId!)
            this.props.history.replace(`${ROUTES.APP_LANDING}${ROUTES.RESULT}?subModuleId=${this.state.subModuleId}&subModuleType=${this.state.subModuleType}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.onFinishDeck(startTime, endTime, deckCardsReport)
            } else {
                this.setState({ isLoading: false, isFinished: true, error: error.toString(), retry: () => { this.onFinishDeck(startTime, endTime, deckCardsReport) } })
            }
        }
    }

    onFinishQuiz = async (startTime: number, endTime: number, quizQuestionsReport: Array<QuizQuestionsReport<QuizQuestion>>) => {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let scoreService = new ScoreService()
            await scoreService.saveUserQuizScore(token, config.endpoint, startTime, endTime, quizQuestionsReport, this.state.subModuleId!)
            this.props.history.replace(`${ROUTES.APP_LANDING}${ROUTES.RESULT}?subModuleId=${this.state.subModuleId}&subModuleType=${this.state.subModuleType}`)
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.onFinishQuiz(startTime, endTime, quizQuestionsReport)
            } else {
                this.setState({ isLoading: false, isFinished: true, error: error.toString(), retry: () => { this.onFinishQuiz(startTime, endTime, quizQuestionsReport) } })
            }
        }
    }

    async loadGameData(subModuleId: string, subModuleType: string) {
        this.setState({ isLoading: true, error: undefined })
        let tokenHelper = new TokenHelper()
        try {
            let token = tokenHelper.getToken()
            if (!token) return this.setState({ error: 'Usuário não possui token de acesso.' })
            let contentService = new ContentService()
            if (subModuleType == SubModuleTypes.DECK) {
                let deckCards = await contentService.getDeckCards(token, config.endpoint, subModuleId)
                let sortedDeckCars = deckCards.sort((a, b) => {
                    return a.pos - b.pos
                })
                this.setState({ deckCards: sortedDeckCars, isLoading: false })
            } else if (subModuleType == SubModuleTypes.QUIZ) {
                let quizQuestions = await contentService.getQuizQuestions(token, config.endpoint, subModuleId)
                this.setState({ quizQuestions, isLoading: false })
            }
        } catch (error) {
            let tokenRefresh = await tokenHelper.refreshTokenIfNeeded(error)
            if (tokenRefresh) {
                this.loadGameData(subModuleId, subModuleType)
            } else {
                this.setState({ isLoading: false, error: error.toString() })
            }
        }
    }

    renderError(error: string) {
        return (
            <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                {error}
            </Alert>
        );
    }

    renderErrorAndRetry(error: string, retry: () => void) {
        return (<Container className='d-flex flex-column' fluid style={{ minHeight: '100vh', background: '#5c959e' }}>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <Alert color="danger" toggle={() => this.setState({ error: undefined })}>
                        {error}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' md={{ size: 6, offset: 3 }}>
                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        <Button color='none' outline onClick={() => { retry() }}><FontAwesomeIcon color='#343a40' icon={faRedo} size='2x' />Tentar Novamente</Button>
                    </div>
                </Col>
            </Row>
        </Container>)
    }

    render() {

        let { company, error, isLoading, deckCards, quizQuestions, retry } = this.state

        if (isLoading) { return <LoadingScreen image={company ? company.pic : undefined} /> }
        if (error && retry) {
            return this.renderErrorAndRetry(error, retry)
        }

        return (<div>
            {error && this.renderError(error)}
            {deckCards && <DeckCardsScreen subModuleId={this.state.subModuleId!} onExit={this.onExit} onFinish={this.onFinishDeck} deckCards={deckCards} companyColor={company ? company.mainColor : undefined} companyPic={company ? company.pic : undefined} />}
            {quizQuestions && <QuizScreen subModuleId={this.state.subModuleId!} onExit={this.onExit} onFinish={this.onFinishQuiz} quizQuestions={quizQuestions} companyColor={company ? company.mainColor : undefined} companyPic={company ? company.pic : undefined} />}
        </div>)
    }
}

const GameScreen = compose<Props, {}>(withRouter)(GameScreenBase)

export default GameScreen
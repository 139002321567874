export default class QuizQuestion {
    id: string
    companyId: string
    moduleId: string
    subModuleId: string
    question: string
    type: string

    constructor(data: any) {
        this.id = data.id
        this.companyId = data.companyId
        this.moduleId = data.moduleId
        this.subModuleId = data.subModuleId
        this.question = data.question
        this.type = data.type
    }

    getData() {
        return {
            id: this.id,
            companyId: this.companyId,
            moduleId: this.moduleId,
            subModuleId: this.subModuleId,
            question: this.question,
            type: this.type
        }
    }
}

export class DefaultQuestion extends QuizQuestion {
    answer0: string
    answer1: string
    answer2?: string
    answer3?: string
    correctAnswer: number
    constructor(data: any) {
        super(data)
        this.answer0 = data.answer0
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.correctAnswer = data.correctAnswer
    }

    getData(): any {
        let result: any = {
            ...super.getData(),
            answer0: this.answer0,
            answer1: this.answer1,
            correctAnswer: this.correctAnswer
        }
        if(this.answer2) result.answer2 = this.answer2
        if(this.answer3) result.answer3 = this.answer3
        return result
    }
}

export function getQuizQuestion(data: any): QuizQuestion {
    if(data.type == QuizQuestionType.Default) {
        return new DefaultQuestion(data)
    } else {
        throw `Unknown card type: ${data.type}`
    }
}

export const QuizQuestionType = {
    Default: 'default',
}